<template>
  <div id="create-operation" class="module-body">
    <div id="panel-header" class="panel-header">
      <span class="top-title">生产操作</span>
      <Button type="primary" class="item_create" v-if="aCheck.rightCheck('edit_create_operate')" @click="itemCreate('add_item')">
        生产创建
      </Button>
    </div>

    <div id="filter-bar" class="filter-bar">
      <Card dis-hover>
        <Form ref="list_search_item" :model="list.search_item" inline>
          <FormItem prop="main_no">
            <Input @on-blur="searchSubmit" v-model="list.search_item.main_no" placeholder="请填写生产批号" class="w-200"/>
          </FormItem>

          <FormItem prop="created_at">
            <DatePicker type="daterange" @on-change="timeChange" placement="bottom-end"
                        placeholder="请选择生产时间" v-model="list.search_item.created_at" class="w-200"></DatePicker>
          </FormItem>

          <FormItem>&nbsp;
            <Button type="text" class="main-font-color" @click="cancelSearch('list_search_item')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>

    <div id="list" class="main-list">
      <Table :columns="list.columns" :data="list.data">
        <template slot-scope="{ row }" slot="main_no">
          <strong @click="viewItem(row)" class="pointer">{{row.main_no}}</strong>
        </template>

        <template slot-scope="{ row }" slot="status">
          <span :style="getStatusColor(row.status)">{{ getStatusText(row.status) }}</span>
        </template>

        <template slot-scope="{ row }" slot="create_start_date">
          <span>{{ row.create_start_date.substring(0, 16) }}</span>
        </template>

        <template slot-scope="{ row, index }" slot="action">
          <!--待审核情况下显示-->
          <Button v-show="[0].includes(row.status)" type="text" size="small" :style="getStatusColor(row.status)" @click="viewItem(row)" v-if="aCheck.rightCheck('valid_create_operate')">
            <Icon type="ios-checkmark-circle"/>
            审核
          </Button>
          <Button v-show="[2].includes(row.status)" type="text" size="small" :style="getStatusColor(row.status)" @click="viewItem(row)">
            <Icon type="ios-checkmark-circle"/>
            查看
          </Button>
          <Button v-show="[1].includes(row.status)" type="text" size="small" :style="getStatusColor(row.status)" @click="viewItem(row)" v-if="!aCheck.rightCheck('operate_create_operate')">
            <Icon type="ios-checkmark-circle"/>
            查看
          </Button>
          <Button v-show="[1].includes(row.status)" type="text" size="small" :style="getStatusColor(row.status)" @click="goodsInItem(row)" v-if="aCheck.rightCheck('operate_create_operate')">
            <Icon type="ios-checkmark-circle"/>
            转入库
          </Button>
          <Dropdown v-show="[0].includes(row.status)" v-if="aCheck.rightCheck('edit_create_operate')">
            <Button size="small" type="text">
              更多
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem v-show="[0].includes(row.status)">
                <span @click="editItem(row)" class="f12">修改</span>
              </DropdownItem>
              <DropdownItem v-show="[0].includes(row.status)">
                <span @click="deleteItem(row)" class="f12">删除</span>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </template>
      </Table>
      <div class="page">
        <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"
              :current="list.page" show-sizer @on-page-size-change="pageSizeChange" :page-size-opts="list.page_size_opts"/>
      </div>
    </div>

    <Modal v-model="add_item.modal" :title="add_item.modal_title" width="800">
      <div id="modalForm">
        <Form ref="add_item" :model="add_item.data" :label-width="100" :rules="add_item.ruleInline"
              label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="生产批号" prop="main_no">
                <Input v-model="add_item.data.main_no" disabled placeholder="请填写生产批号"></Input>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="主题" prop="subject">
                <Input v-model="add_item.data.subject" maxlength="255" placeholder="请填写主题"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="开始日期" prop="create_start_date">
                <DatePicker :value="add_item.data.create_start_date" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                          placeholder="请填写生产时间(不填，默认当前时间)" style="width: 274px" @on-change="getStartDateTime"></DatePicker>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="结束日期" prop="create_end_date">
                <DatePicker :value="add_item.data.create_end_date" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                            placeholder="请填写生产时间(不填，默认当前时间)" style="width: 274px" @on-change="getDateTime"></DatePicker>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="备注" prop="remark">
                <Input v-model="add_item.data.remark" maxlength="255" placeholder="请填写备注"></Input>
              </FormItem>
            </Col>
          </Row>

          <Row class="mt2 display-block">
            <Card :bordered="false">
              <p slot="title">货品生产</p>
<!--              <Button type="info" slot="extra" size="small" @click="setProductMaterial()" class="f12">-->
<!--                <Icon type="md-refresh"/>-->
<!--                BOM消耗生成-->
<!--              </Button>-->
              <Button type="primary" slot="extra" size="small" @click="addProductGoods()" class="f12 ml-10">
                <Icon type="md-add"/>
                选择货品
              </Button>
              <Table :columns="common.goods_product_columns" :data="add_item.data.product_goods_set">
                <template slot-scope="{ row }" slot="thumbnail_url">
                  <p><img :src="row.thumbnail_url" alt="" class="preview-img" v-show="row.thumbnail_url"></p>
                </template>
                <template slot-scope="{ row, index }" slot="transfer_num">
                  <p>
                    <i-input type="number" v-model="row.transfer_num" @on-change="setProductNum(row, index)"/>
                  </p>
                </template>
                <template slot-scope="{ row }" slot="stock_all">
                  <span>{{ minus(row.stock_all, row.frozen_stock) }}</span>
                </template>

                <template slot-scope="{ row, index }" slot="action">
                  <Button class="fs12" type="text" size="small" @click="deleteProductGoodsSelected(row, index)">
                    <Icon type="ios-trash"/>
                    删除
                  </Button>
                </template>
              </Table>
            </Card>
          </Row>

          <Row class="mt2 display-block">
            <Card :bordered="false">
              <p slot="title">物料消耗</p>
              <Button type="primary" slot="extra" size="small" @click="addMaterialGoods()" class="f12">
                <Icon type="md-add"/>
                选择物料
              </Button>
              <Table :columns="common.goods_material_columns" :data="add_item.data.material_goods_set">
                <template slot-scope="{ row }" slot="thumbnail_url">
                  <p><img :src="row.thumbnail_url" alt="" class="preview-img" v-show="row.thumbnail_url"></p>
                </template>

                <template slot-scope="{ row }" slot="stock_all">
                  <span>{{ minus(row.stock_all, row.frozen_stock) }}</span>
                </template>

                <template slot-scope="{ row, index }" slot="transfer_num">
                  <p>
                    <i-input type="number" v-model="row.transfer_num" @on-change="setMaterialNum(row, index)"/>
                  </p>
                </template>

                <template slot-scope="{ row, index }" slot="action">
                  <Button class="fs12" type="text" size="small" @click="deleteMaterialGoodsSelected(row, index)">
                    <Icon type="ios-trash"/>
                    删除
                  </Button>
                </template>
              </Table>
            </Card>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="cancelAdd">关闭</Button>
        <Button type="primary" :loading="add_item.modal_loading" @click="saveCreateOperation('add_item')">保存
        </Button>
      </div>
    </Modal>

    <Modal v-model="view_item.modal" :title="view_item.modal_title" width="960">
      <div id="viewModalForm">
        <Form ref="view_item" :model="view_item.data" :label-width="100" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="生产批号" prop="main_no">
                <span>{{view_item.data.main_no}}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="主题" prop="subject">
                <span>{{view_item.data.subject}}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="开始日期" prop="create_start_date">
                <span>{{view_item.data.create_start_date}}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="结束日期" prop="create_end_date">
                <span>{{view_item.data.create_end_date}}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="备注" prop="remark">
                <span>{{view_item.data.remark}}</span>
              </FormItem>
            </Col>
          </Row>

          <Row class="mt2 display-block">
            <Card :bordered="false">
              <p slot="title">货品生产</p>
              <Table :columns="common.goods_product_columns" :data="view_item.data.product_goods_set">
                <template slot-scope="{ row }" slot="thumbnail_url">
                  <p><img :src="row.thumbnail_url" alt="" class="preview-img" v-show="row.thumbnail_url"></p>
                </template>
                <template slot-scope="{ row, index }" slot="transfer_num">
                  <span>{{ row.transfer_num }}</span>
                </template>
                <template slot-scope="{ row }" slot="stock_all">
                  <span>{{ minus(row.stock_all, row.frozen_stock) }}</span>
                </template>

                <template slot-scope="{ row, index }" slot="action">
                  <span class="disabled-color fs-12">------</span>
                </template>
              </Table>
            </Card>
          </Row>

          <Row class="mt2 display-block">
            <Card :bordered="false">
              <p slot="title">物料消耗</p>
              <Table :columns="common.goods_material_columns" :data="view_item.data.material_goods_set">
                <template slot-scope="{ row }" slot="thumbnail_url">
                  <p><img :src="row.thumbnail_url" alt="" class="preview-img" v-show="row.thumbnail_url"></p>
                </template>

<!--                <template slot-scope="{ row }" slot="stock_all">-->
<!--                  <span>{{ minus(row.stock_all, row.frozen_stock) }}</span>-->
<!--                </template>-->

                <template slot-scope="{ row, index }" slot="transfer_num">
                  <span>{{ row.transfer_num }}</span>
                </template>

                <template slot-scope="{ row, index }" slot="action">
                  <span class="disabled-color fs-12">------</span>
                </template>
              </Table>
            </Card>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="cancelView">关闭</Button>
        <Button type="success" v-show="view_item.data.status==0" :loading="view_item.modal_loading" @click="passViewItem(view_item.data.id, view_item.data.main_no)" v-if="aCheck.rightCheck('valid_create_operate')">
          审核通过
        </Button>
      </div>
    </Modal>

    <SelectGoods ref="select_goods" @selected="getSelectedGoods" :select_goods="select_goods"/>
    <SelectGoods ref="select_material_goods" @selected="getSelectedMaterialGoods" :select_goods="select_material_goods"/>

  </div>
</template>

<script>
import {
  saveCreateOperation,
  getCreateOperationMainNo,
  getCreateList,
  getCreateDetail,
  passCreateOperate,
  deleteCreateInfo,
  goodsCreateInStock
} from '@/api/create/createOperation';

import SelectGoods from '@/components/common/SelectGoods.vue';
import {getBomDetailByGoodsIds} from "@/api/create/bom";
import NP from "number-precision";


export default {
  name: "CreateOperation",
  data() {
    return {
      select_goods: {
        modal: false,
        count_flag: 0,
        goods_type: 2,
        client_id: 0,
      },
      select_material_goods: {
        modal: false,
        count_flag: 0,
        goods_type: 0,
        client_id: 0,
      },
      list: {
        page_size_key: 'create_operate_page_size_key',
        page_size_opts: [10, 15, 20, 30],
        search_item: {
          main_no: '',
          target_house_id: '',
          from_house_id: '',
          created_at: []
        },
        columns: [
          {
            title: '生产批号',
            key: 'main_no',
            slot: 'main_no',
            align: 'center',
          },
          {
            title: '标题',
            key: 'subject',
            align: 'center',
          },
          {
            title: '生产日期',
            key: 'create_start_date',
            slot: 'create_start_date',
            align: 'center',
          },
          {
            title: '负责人',
            key: 'operator_name',
            align: 'center',
          },
          {
            title: '状态',
            key: 'status',
            slot: 'status',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            slot: 'action',
            align: 'center',
          },
        ],
        data: [],
        page: 1,
        size: 10,
      },
      add_item: {
        modal: false,
        modal_title: '生产创建操作',
        modal_loading: false,
        data: {
          id: 0,
          main_no: '',
          subject: '',
          create_start_date: '',
          create_end_date: '',
          remark: '',
          product_goods_set: [],
          material_goods_set: [],
        },
        ruleInline: {
          subject: [
            {required: true, message: '请填写主题'},
          ],
        }
      },
      view_item: {
        modal: false,
        modal_title: '查看生产单',
        data: {
          id: 0,
          main_no: '',
          subject: '',
          create_start_date: '',
          create_end_date: '',
          remark: '',
          plan_id: '',
          product_goods_set: [],
          material_goods_set: [],
        },
      },
      common: {
        goods_material_columns:[
          {
            title: '货品编号',
            key: 'part_no',
            align: 'center',
          },
          {
            title: '货品名称',
            key: 'part_name',
            align: 'center',
          },
          {
            title: '缩略图',
            key: 'thumbnail_url',
            slot: 'thumbnail_url',
            align: 'center',
          },
          {
            title: '消耗数量',
            key: 'transfer_num',
            slot: 'transfer_num',
            align: 'center',
          },
          // {
          //   title: '领料库存',
          //   slot: 'stock_all',
          //   key: 'stock_all',
          //   align: 'center',
          // },
          {
            title: '操作',
            key: 'action',
            slot: 'action'
          }
        ],
        goods_product_columns: [
          {
            title: '货品编号',
            key: 'part_no',
            align: 'center',
          },
          {
            title: '货品名称',
            key: 'part_name',
            align: 'center',
          },
          {
            title: '缩略图',
            key: 'thumbnail_url',
            slot: 'thumbnail_url',
            align: 'center',
          },
          {
            title: '生产数量',
            key: 'transfer_num',
            slot: 'transfer_num',
            align: 'center',
          },
          {
            title: '库存',
            slot: 'stock_all',
            key: 'stock_all',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            slot: 'action'
          }
        ],
      }
    }
  },
  methods: {
    minus(a, b) {
      return NP.minus(a, b)
    },
    goodsInItem(row) {
      this.$Modal.confirm({
        title: "生产单执行信息",
        content: '确定将货品转入仓库？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          goodsCreateInStock({create_id: row.id, main_no:row.main_no}).then(res => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              this.getMainList();
            } else {
              this.$Message.error(res.data.err_msg);
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.list.page = 1;
      this.getMainList();
    },
    searchSubmit() {
      this.list.page = 1;
      this.getMainList();
    },
    deleteItem(row) {
      this.$Modal.confirm({
        title: "删除生产单信息",
        content: '确定删除生产单信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          deleteCreateInfo({create_id:row.id}).then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.getMainList();
            } else {
              this.$Message.error(response.data.results);
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    editItem(row) {
      this.add_item.modal_loading = false;
      this.add_item.data.id = row.id;
      this.add_item.data.subject = row.subject;
      this.add_item.data.create_start_date = row.create_start_date;
      this.add_item.data.create_end_date = row.create_end_date;
      this.add_item.data.remark = row.remark;
      this.add_item.data.main_no = row.main_no;
      this.add_item.data.product_goods_set = [];
      this.add_item.data.material_goods_set = [];
      getCreateDetail({create_id:row.id}).then((res) => {
        if (res.data.err_no == 0) {
          for (let i=0; i<res.data.results.product_goods_set.length; i++) {
            res.data.results.product_goods_set[i].transfer_num = parseFloat(res.data.results.product_goods_set[i].transfer_num);
            res.data.results.product_goods_set[i].stock_all = parseFloat(res.data.results.product_goods_set[i].stock_all);
            res.data.results.product_goods_set[i].frozen_stock = parseFloat(res.data.results.product_goods_set[i].frozen_stock);
          }

          for (let i=0; i<res.data.results.material_goods_set.length; i++) {
            res.data.results.material_goods_set[i].transfer_num = parseFloat(res.data.results.material_goods_set[i].transfer_num);
            res.data.results.material_goods_set[i].stock_all = parseFloat(res.data.results.material_goods_set[i].stock_all);
            res.data.results.material_goods_set[i].frozen_stock = parseFloat(res.data.results.material_goods_set[i].frozen_stock);
          }
          this.add_item.data.product_goods_set = res.data.results.product_goods_set;
          this.add_item.data.material_goods_set = res.data.results.material_goods_set;
          this.add_item.modal = true;
        }
      });

    },
    cancelView() {
      this.view_item.modal = false;
    },
    passViewItem(id, main_no) {
      this.view_item.modal_loading = true;
      let param = {
        create_id: id,
        main_no: main_no,
        plan_id: this.view_item.data.plan_id,
      }
      passCreateOperate(param).then((res) => {
        if (res.data.err_no == 0) {
          this.view_item.modal = false;
          this.$Message.success(res.data.results);
          this.getMainList();
        }
        this.view_item.modal_loading = false;
      });
    },
    viewItem(row) {
      this.view_item.data = row;
      getCreateDetail({create_id:row.id}).then((res) => {
        if (res.data.err_no == 0) {
          for (let i=0; i<res.data.results.product_goods_set.length; i++) {
            res.data.results.product_goods_set[i].transfer_num = parseFloat(res.data.results.product_goods_set[i].transfer_num);
            res.data.results.product_goods_set[i].stock_all = parseFloat(res.data.results.product_goods_set[i].stock_all);
            res.data.results.product_goods_set[i].frozen_stock = parseFloat(res.data.results.product_goods_set[i].frozen_stock);
          }

          for (let i=0; i<res.data.results.material_goods_set.length; i++) {
            res.data.results.material_goods_set[i].transfer_num = parseFloat(res.data.results.material_goods_set[i].transfer_num);
            res.data.results.material_goods_set[i].stock_all = parseFloat(res.data.results.material_goods_set[i].stock_all);
            res.data.results.material_goods_set[i].frozen_stock = parseFloat(res.data.results.material_goods_set[i].frozen_stock);
          }

          this.view_item.data.product_goods_set = res.data.results.product_goods_set;
          this.view_item.data.material_goods_set = res.data.results.material_goods_set
          this.view_item.modal = true;
        }
      });
    },
    getStatusColor(index) {
      let rs = '';
      switch (index) {
        case 0:
          rs = 'color:#ed4014';
          break;
        case 1:
          rs = 'color:#19be6b';
          break;
        case 2:
          rs = 'color:#2775ff';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getStatusText(index) {
      let rs = '';
      switch (index) {
        case 0:
          rs = '待审核';
          break;
        case 1:
          rs = '已审核';
          break;
        case 2:
          rs = '已入库';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    setMaterialNum(row, index) {
      if (isNaN(row.transfer_num) ) {
        this.add_item.data.material_goods_set[index].transfer_num = 0;
      } else {
        //赋值
        this.add_item.data.material_goods_set[index].transfer_num = row.transfer_num;
      }
    },
    setProductNum(row, index) {
      if (isNaN(row.transfer_num) ) {
        this.add_item.data.product_goods_set[index].transfer_num = 0;
      } else {
        //赋值
        this.add_item.data.product_goods_set[index].transfer_num = row.transfer_num;
      }
    },
    saveCreateOperation(name) {
      this.add_item.modal_loading = true;

      this.$refs[name].validate((valid) => {
        if (!valid) {
          this.add_item.modal_loading = false;
          return;
        }

        //判断是否添加了销售货品
        let product_goods_set = [];
        if (this.add_item.data.product_goods_set.length < 1) {
          this.$Message.error('请添加生成的货品');
          this.add_item.modal_loading = true;
          return;
        }

        let material_goods_set = [];
        if (this.add_item.data.material_goods_set.length < 1) {
          this.$Message.error('请添加生产所需物料');
          this.add_item.modal_loading = true;
          return;
        }

        //过滤无效数据
        for (let i = 0; i < this.add_item.data.product_goods_set.length; i++) {
          let tmp = {
            goods_id: '',
            transfer_num: 0,
          };

          tmp.goods_id = this.add_item.data.product_goods_set[i].goods_id;
          tmp.transfer_num = this.add_item.data.product_goods_set[i].transfer_num;

          if (parseInt(tmp.transfer_num) <= 0) {
            this.$Message.error('生产货品数量不能小于等于0');
            this.add_item.modal_loading = false;
            return;
          }

          product_goods_set[i] = tmp;
        }

        for (let i = 0; i < this.add_item.data.material_goods_set.length; i++) {
          let tmp = {
            goods_id: '',
            transfer_num: 0,
          };

          tmp.goods_id = this.add_item.data.material_goods_set[i].goods_id;
          tmp.transfer_num = this.add_item.data.material_goods_set[i].transfer_num;

          if (parseFloat(tmp.transfer_num) <= 0) {
            this.$Message.error('生产物料数量不能小于等于0');
            this.add_item.modal_loading = false;
            return;
          }

          material_goods_set[i] = tmp;
        }

        //判断物料和货品是否相同
        for (let i = 0; i < this.add_item.data.material_goods_set.length; i++) {
          for (let j = 0; j < this.add_item.data.product_goods_set.length; j++) {
            if (this.add_item.data.material_goods_set[i].goods_id == this.add_item.data.product_goods_set[j].goods_id) {
              this.$Message.error('物料和货品不能重复！！！');
              this.add_item.modal_loading = false;
              return;
            }
          }
        }


        let param = {
          id: this.add_item.data.id,
          main_no: this.add_item.data.main_no,
          subject: this.add_item.data.subject,
          remark: this.add_item.data.remark,
          product_goods_set: product_goods_set,
          material_goods_set: material_goods_set,
          create_end_date: this.add_item.data.create_end_date,
          create_start_date: this.add_item.data.create_start_date,
        };

        saveCreateOperation(param).then((res) => {
          if (res.data.err_no == 0) {
            this.$Message.success(res.data.results);
            this.add_item.modal = false;
            this.getMainList();
          } else {
            this.$Message.error(res.data.err_msg);
            this.add_item.modal_loading = false;
          }
        }).catch((error) => {
          this.$Message.error('保存失败');
          this.add_item.modal_loading = false;
        });

      });
    },
    deleteProductGoodsSelected(row, index) {
      this.add_item.data.product_goods_set.splice(index, 1);
    },
    deleteMaterialGoodsSelected(row, index) {
      this.add_item.data.material_goods_set.splice(index, 1);
    },
    getSelectedGoods(val) {
      //获取选中的货品
      let selected_goods = val;
      let goods_set = this.add_item.data.product_goods_set;

      if (selected_goods.length) {
        for (let i = 0, len = selected_goods.length; i < len; i++) {
          let added_flag = false;

          //排除已添加的
          for (let j = 0, jLen = goods_set.length; j < jLen; j++) {
            if (goods_set[j].id == selected_goods[i].id) {
              added_flag = true;
              break;
            }
          }

          //如果未添加
          if (!added_flag) {
            selected_goods[i].transfer_num = '';
            goods_set.push(selected_goods[i]);
          }
        }
      }
    },
    getSelectedMaterialGoods(val) {
      //获取选中的货品
      let selected_goods = val;
      let goods_set = this.add_item.data.material_goods_set;

      if (selected_goods.length) {
        for (let i = 0, len = selected_goods.length; i < len; i++) {
          let added_flag = false;

          //排除已添加的
          for (let j = 0, jLen = goods_set.length; j < jLen; j++) {
            if (goods_set[j].id == selected_goods[i].id) {
              added_flag = true;
              break;
            }
          }

          //如果未添加
          if (!added_flag) {
            selected_goods[i].transfer_num = '';
            goods_set.push(selected_goods[i]);
          }
        }
      }
    },
    //添加货品
    addProductGoods() {
      this.$refs.select_goods.select_goods.client_id = 0
      this.$refs.select_goods.select_goods.goods_type = this.select_goods.goods_type;
      this.$refs.select_goods.showGoodsList();
    },
    setProductMaterial() {//根据货品bom结构获取物流信息
      if (this.add_item.data.product_goods_set.length <= 0) {
        this.$Message.error('请先选择生产的货品！');
        return;
      }

      let goods_ids = [];
      for(let i=0; i<this.add_item.data.product_goods_set.length; i++) {
        goods_ids.push(this.add_item.data.product_goods_set[i].goods_id)
      }

      getBomDetailByGoodsIds({goods_ids:goods_ids}).then(res => {
        if (res.data.results.length > 0) {
          for (let j=0; j<res.data.results.length; j++) {
            for(let i=0; i<this.add_item.data.product_goods_set.length; i++) {
              if (res.data.results[j].parent_goods_id = this.add_item.data.product_goods_set[i].goods_id) {
                if (this.add_item.data.material_goods_set.length > 0) {
                  for (let k=0; k<this.add_item.data.material_goods_set.length; k++) {

                  }
                }
              }
            }
          }

        }
      })

    },
    addMaterialGoods() {
      this.$refs.select_material_goods.select_goods.client_id = 0
      this.$refs.select_material_goods.select_goods.goods_type = this.select_material_goods.goods_type;
      this.$refs.select_material_goods.showGoodsList();
    },
    getStartDateTime(value, type) {
      this.add_item.data.create_start_date = value;
    },
    getDateTime(value, type) {
      this.add_item.data.create_end_date = value;
    },
    cancelAdd() {
      //弹出框重置
      this.add_item.modal = false;
      this.add_item.modal_loading = false;

    },
    itemCreate(name) {
      this.$refs[name].resetFields();
      this.add_item.data.product_goods_set = [];
      this.add_item.data.material_goods_set = [];

      getCreateOperationMainNo().then(res => {
        this.add_item.data.main_no = res.data.results;
        this.add_item.modal = true;
        this.add_item.modal_loading = false;
      });
    },
    timeChange(dateRange) {
      if (dateRange[0] && dateRange[1]) {
        this.list.search_item.created_at = dateRange;
      } else {
        this.list.search_item.created_at = "";
      }

      this.searchSubmit();
    },
    pageSizeChange(page_size) {
      this.list.size = page_size;
      localStorage.setItem(this.list.page_size_key, page_size);
      this.getMainList();
    },
    changePage(page) {
      this.list.page = page;
      this.getMainList();
    },
    getMainList() {
      let param = {
        page: this.list.page,
        size: this.list.size,
        main_no: this.list.search_item.main_no,
        created_at: this.list.search_item.created_at,
      };

      getCreateList(param).then((res) => {
        this.list.data = res.data.results.list;
        this.list.total = res.data.results.total;
        this.list.size = res.data.results.size;
      });
    },
    setSelectedPageSize() {
      let size = localStorage.getItem(this.list.page_size_key);
      this.list.size = size ? (size > 50 ? 40 : parseInt(size)) : 0;
    },
  },
  components: {
    SelectGoods,
  },
  mounted() {
    this.setSelectedPageSize();
    this.getMainList();
  },
  created() {
    this.aCheck.plateName = 'create_manage';
  },
}
</script>

<style scoped>
#create-operation .ivu-card {
  background-color: #fafafa;
}

#create-operation .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#list .page {
  margin-top: 16px;
}

.item_create {
  position: absolute;
  right: 0;
  top: -8px;
}
</style>
<style>
#create-operation .ivu-form-item {
  margin-bottom: 0;
}

#list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#list .ivu-page {
  float: right;
}

#list .ivu-spin-fix {
  top: 40px !important;
  height:calc(100% - 40px) !important;
}

#list .ivu-btn-small {
  font-size: 12px !important;
}

#list .ivu-table-overflowX, .ivu-table-tip {
  overflow-x: hidden;
}

#list .ivu-table-wrapper {
  overflow: visible;
}
</style>
