import request from '@/plugins/http'

export function getBomDetailByGoodsIds(param) {
    return request.post('/api/create/getBomDetailByGoodsIds', param);
}

export function getBomList(param) {
    return request.post('/api/create/getBomList', param);
}

export function createGoodsCategoryByCopy(param) {
    return request.post('/api/create/createGoodsCategoryByCopy', param);
}

export function saveBomGoodsCategoryBatch(param) {
    return request.post('/api/create/saveBomGoodsCategoryBatch', param);
}

export function deleteBom(param) {
    return request.post('/api/create/deleteBom', param);
}






