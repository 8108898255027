<template>
  <div class="layout">
    <Layout>
      <Layout>
        <Sider width="200">
          <CreateManageMenu ref="createManageMenu" @func="getMsgFormMenu" :active="title_seclectd" :menuName="menuName"></CreateManageMenu>
        </Sider>
        <Content>
          <NavTag ref="navTag" @func="getMsgFormMenu"></NavTag>
          <PickGoods v-if="title_seclectd == 5"></PickGoods>
          <CreateOperation v-if="title_seclectd == 4"></CreateOperation>
          <CreateRequire v-if="title_seclectd == 3"></CreateRequire>
          <GoodsCreatePlan v-if="title_seclectd == 2"></GoodsCreatePlan>
          <BomTable v-if="title_seclectd == 1"></BomTable>
        </Content>
      </Layout>
    </Layout>
  </div>
</template>

<script>
import CreateManageMenu from '@/components/menu/CreateManageMenu.vue';
import GoodsCreatePlan from '@/views/createManage/GoodsCreatePlan.vue';
import BomTable from '@/views/createManage/BomTable.vue';
import CreateRequire from '@/views/createManage/CreateRequire.vue';
import CreateOperation from '@/views/createManage/CreateOperation';
import PickGoods from '@/views/createManage/PickGoods';

import NavTag from '@/components/menu/NavTags.vue';



export default {
  name: "CreateManage",
  components: {
    CreateManageMenu,
    PickGoods,
    GoodsCreatePlan,
    BomTable,
    CreateRequire,
    CreateOperation,
    NavTag
  },
  data() {
    return {
      title_seclectd: "1",
      menuName: "CreateRequire",
    };
  },
  mounted() {
    this.title_seclectd = localStorage.getItem(this.menuName) ? localStorage.getItem(this.menuName) : "1";

    this.setNavInfo(this.title_seclectd)
  },

  methods: {
    getMsgFormMenu(data) {
      this.title_seclectd = data;
      this.setNavInfo(data)
    },
    setNavInfo(data) {
      this.$refs.navTag.setNavList(this.$route.path, this.title_seclectd)
      this.$refs.createManageMenu.setNavInfo(data)
    }
  },
};
</script>

<style scoped>
.ivu-layout {
  background-color: transparent;
}

.ivu-layout-sider {
  background-color: transparent;
}

.ivu-menu {
  height: 100%;
}

.laypout {
  display: flex;
}
</style>
