<template>
  <div id="clientInfo">
    <div id="panel-header">
      <span class="top-title">生产需求</span>
      <Button type="primary" class="client_create" @click="create('clientFormItem')">添加需求</Button>
    </div>
    <!--列表筛选条件-->
    <div id="filter-bar">
      <Card style="width:100%" dis-hover>
        <Form :model="formItem" :label-width="80" inline>
          <Row>
            <Col span="6">
              <FormItem label="需求编号">
                <i-input v-model="formItem.main_no" size="small" placeholder="请填写需求编号"/>
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem label="需求状态">
                <Select v-model="formItem.status" size="small" placeholder="请选择需求状态" filterable>
                  <Option v-for="item in all_status" :value="item.id" :key="item.id">
                    {{ item.name }}
                  </Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem label="订单时间">
                <DatePicker size="small" type="daterange" @on-change="timeChange" placement="bottom-end"
                            placeholder="请选择创建时间" style="width: 200px"></DatePicker>
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem :label-width="20">
                <Button type="primary" size="small" @click="searchSubmit()" shape="circle"
                        icon="ios-search">搜索
                </Button>&nbsp;&nbsp;
                <Button type="default" size="small" @click="cancelSearch()" shape="circle">重置
                </Button>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>

    <!--列表-->
    <div id="list" class="list">
      <Table :columns="list.columns" :data="list.data">
        <template slot-scope="{ row }" slot="main_no">
          <strong @click="checkCreatePlan(row)" class="pointer">{{ row.main_no }}</strong>
        </template>
        <template slot-scope="{ row }" slot="status">
          <span :style="getStatusColor(row.status)" v-for="item in all_status"
                v-show="item.id==row.status">{{ item.name }}</span>
        </template>

        <template slot-scope="{ row, index }" slot="action">
          <!--待审核情况下显示-->
          <Button v-show="row.status==0" type="text" size="small" :style="getStatusColor(row.status)"
                  @click="checkCreatePlan(row)">
            <Icon type="ios-checkmark-circle"/>
            审核
          </Button>

          <Button v-show="row.status==1" type="text" size="small" :style="getStatusColor(row.status)"
                  @click="startCreatePlan(row)">
            <Icon type="ios-checkmark-circle"/>
            开始需求
          </Button>

          <Button v-show="row.status==2" type="text" size="small" :style="getStatusColor(row.status)"
                  @click="checkCreatePlan(row)">
            <Icon type="ios-checkmark-circle"/>
            操作需求
          </Button>


          <!--<Button v-show="row.status==5||row.status==4 ||row.status==6" type="text" size="small"-->
          <!--style="color:#19be6b" @click="checkCreatePlan(row)">-->
          <!--<Icon type="ios-checkmark-circle"/>-->
          <!--查看-->
          <!--</Button>-->
          <Dropdown v-show="row.status==0">
            <Button size="small" type="text">
              更多
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem v-show="row.status==0">
                <span @click="listEdit(row)" class="f12">修改需求</span>
              </DropdownItem>
              <DropdownItem v-show="row.status==0">
                <span @click="listDeleteAlert(row)" class="f12">删除需求</span>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </template>
      </Table>
      <div class="page">
        <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"/>
      </div>

    </div>

    <!--添加修改需求-->
    <Modal v-model="add_edit.modal" :title="add_edit.model_title" width="960">
      <div class="modalForm">
        <Form ref="clientFormItem" :model="clientFormItem" :label-width="100" :rules="ruleInline" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="需求编号" prop="main_no">
                <Input v-model="clientFormItem.main_no" disabled placeholder="请填写需求编号"></Input>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="需求名称" prop="name">
                <Input v-model="clientFormItem.name" placeholder="请填写需求名称"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="预计完成时间" prop="expect_finish_time">
                <DatePicker type="date" v-model="clientFormItem.expect_finish_time" show-week-numbers
                            placeholder="日期选择" style="width: 100%" format="yyyy-MM-dd"></DatePicker>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="备注" prop="remark">
                <Input v-model="clientFormItem.remark" placeholder="请填写备注信息"></Input>
              </FormItem>
            </Col>
          </Row>

          <Row :gutter="20">
            <Col span="24">
              <Button icon="md-add" type="primary" @click="addGoods('2,3')">添加货品</Button>
            </Col>
          </Row>
          <Row class="mt2 display-block">
            <Table :columns="clientFormItem.goods_columns" :data="clientFormItem.goods_setted">
              <template slot-scope="{ row, index }" slot="part_no">
                <span>{{ row.part_no }}</span>
              </template>

              <template slot-scope="{ row }" slot="thumbnail_url">
                <p><img :src="row.thumbnail_url" alt="" class="preview-img" v-show="row.thumbnail_url"></p>
              </template>

              <template slot-scope="{ row, index }" slot="plan_num">
                <p>
                  <i-input type="number" v-model="row.plan_num" @on-change="setNum(row, index)"/>
                </p>
              </template>

              <template slot-scope="{ row }" slot="stock_all">
                <span>{{ minus(row.stock_all, row.frozen_stock) }}</span>
              </template>

              <template slot-scope="{ row, index }" slot="action">
                <Button type="text" size="small" @click="deleteGoodsSelected(row, index)">
                  <Icon type="ios-trash"/>
                  删除
                </Button>
              </template>
            </Table>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleReset('clientFormItem')">取消</Button>
        <Button type="primary" :loading="modal_loading.add_edit" @click="createCreateProject('clientFormItem')">
          保存
        </Button>
      </div>
    </Modal>

    <!--查看-->
    <Modal v-model="check_order.modal" :title="check_order.title" width="1080">
      <div class="modalForm">
        <Form :model="clientFormItem" :label-width="120" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="需求单号">
                <span>{{ clientFormItem.main_no }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="需求名称">
                <span>{{ clientFormItem.name }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="预计完成时间">
                <span>{{ clientFormItem.expect_finish_time ? dateFormat(clientFormItem.expect_finish_time) : '' }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="备注">
                <span>{{ clientFormItem.remark }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="需求状态">
                                <span :style="getStatusColor(clientFormItem.status)" v-for="item in all_status"
                                      v-show="item.id==clientFormItem.status">{{ item.name }}</span>
              </FormItem>
            </Col>
          </Row>

          <Row style="margin-top:2px;">
            <Card :bordered="false">
              <p slot="title">需求详情</p>
              <Table :columns="clientFormItem.goods_columns" :data="clientFormItem.goods_setted">
                <template slot-scope="{ row, index }" slot="part_no">
                  <span>({{ row.part_no }}) {{ row.part_name }}</span>&nbsp;
                  <code v-if="!row.bom_flag">暂无BOM</code>
                </template>

                <template slot-scope="{ row }" slot="thumbnail_url">
                  <p><img :src="row.thumbnail_url" alt="" class="preview-img" v-show="row.thumbnail_url"></p>
                </template>

                <template slot-scope="{ row }" slot="stock_all">
                  <span>{{ minus(row.stock_all, row.frozen_stock) }}</span>
                </template>

                <template slot-scope="{ row, index }" slot="action">
                  <Button type="text" size="small" v-show="clientFormItem.status==2"
                          class="button-in-text-blue">
                    <Icon type="ios-hammer"/>
                    生产
                  </Button>

                  <Button type="text" size="small" v-show="clientFormItem.status==2"
                          class="button-in-text-blue" @click="showPicking(row)">
                    <Icon type="ios-walk"/>
                    领料
                  </Button>

                  <Button type="text" size="small" v-show="clientFormItem.status==2"
                          class="button-in-text-blue">
                    <Icon type="ios-filing"/>
                    入库
                  </Button>
                </template>
              </Table>
            </Card>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleResetOrderModal(clientFormItem)">取消</Button>
        <Button type="primary" v-show="clientFormItem.status==0" @click="showEditSaleOrder(clientFormItem)">
          修改订单
        </Button>
        <Button type="success" v-show="clientFormItem.status==0" :loading="modal_loading.success"
                @click="passCreatePlan(clientFormItem)">审核通过
        </Button>

        <Button type="success" v-show="clientFormItem.status==1" :loading="modal_loading.success"
                @click="startCreatePlan(clientFormItem)">开始需求
        </Button>

      </div>
    </Modal>

    <!--领料弹框-->
    <Modal v-model="picking.modal" :title="picking.title" width="1080">
      <Row>
        <Card :bordered="false">
          <p slot="title">领料详情</p>
          <Table :columns="picking.goods_columns" :data="picking.goods_setted">
            <template slot-scope="{ row, index }" slot="part_no">
              <span>({{ row.part_no }}) {{ row.part_name }}</span>&nbsp;
              <code v-if="!row.bom_flag">暂无BOM</code>
            </template>

            <template slot-scope="{ row }" slot="thumbnail_url">
              <p><img :src="row.thumbnail_url" alt="" class="preview-img" v-show="row.thumbnail_url"></p>
            </template>

            <template slot-scope="{ row }" slot="stock_all">
              <span>{{ minus(row.stock_all, row.frozen_stock) }}</span>
            </template>
          </Table>
        </Card>
      </Row>

      <Row>
        <Card :bordered="false">
          <p slot="title">领料申请记录</p>
          <p slot="extra">
            <Button type="text" size="small" class="button-in-text-blue" @click="applyGoodsView">
              领料申请
            </Button>
          </p>
          <Table :columns="picking.log_columns" :data="picking.log_setted">
            <template slot-scope="{ row, index }" slot="part_no">
              <span>({{ row.part_no }}) {{ row.part_name }}</span>&nbsp;
              <code v-if="!row.bom_flag">暂无BOM</code>
            </template>

          </Table>
        </Card>
      </Row>
      <div slot="footer">
        <Button @click="handleResetPickModal">取消</Button>
      </div>
    </Modal>

    <!--领料申请弹框-->
    <Modal v-model="picking_view.modal" :title="picking_view.title" width="1080">
      <Row>
        <span>如果货品生产数：    <Input style="width:60px;" v-model="picking_view.plan_num" size="small" placeholder="货品生产数"
                                 @on-blur="getPickingViewNum"/>，需要原材料数量如下：</span>
      </Row>
      <Row class="mt10">
        <Table :columns="picking_view.goods_columns" :data="picking_view.goods_setted" row-key="id">
          <template slot-scope="{ row }" slot="stock_all">
            <span>{{ minus(row.stock_all, row.frozen_stock) }}</span>
          </template>
        </Table>
      </Row>
      <div slot="footer">
        <Button @click="handleResetPickViewModal">取消</Button>
      </div>
    </Modal>

    <SelectGoods ref="select_goods" @selected="getSelectedGoods" :select_goods="select_goods"/>

  </div>


</template>

<script>
import '@/css/common.css';
import SelectGoods from '@/components/common/SelectGoods.vue';
import NP from "number-precision";

export default {
  name: "CreateRequire",
  data() {
    return {
      select_goods: {
        modal: false,
        count_flag: 0,
        goods_type: 0
      },

      formItem: {
        main_no: '',
        status: '',
        created_at: '',
      },

      picking: {
        modal: false,
        goods_id: '',
        title: '需求领料信息',
        goods_columns: [
          {
            title: '货品',
            key: 'part_no',
            slot: 'part_no',
            align: 'center',
          },
          {
            title: '缩略图',
            key: 'thumbnail_url',
            slot: 'thumbnail_url',
            align: 'center',
          },
          {
            title: '总领料数',
            key: 'plan_num',
            align: 'center',
          },
          {
            title: '已使用',
            key: 'created_num',
            align: 'center',
          },
          {
            title: '剩余',
            key: 'in_num',
            align: 'center',
          },
        ],
        log_columns: [
          {
            title: '记录ID',
            key: 'id',
            align: 'center',
          },
          {
            title: '记录状态',
            key: 'status',
            align: 'center',
          },
          {
            title: '操作员',
            key: 'operator_name',
            align: 'center',
          },
          {
            title: '操作时间',
            key: 'updated_at',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            // slot: 'action',
            align: 'center',
          },
        ],
      },

      picking_view: {
        modal: false,
        title: '领料申请信息',
        goods_columns: [
          {
            title: '货品编号',
            key: 'part_no',
            tree: true,
          },
          {
            title: '货品名称',
            key: 'part_name',
          },
          {
            title: '建议领料',
            key: 'need_num',
            align: 'center',
          },
          {
            title: '库存',
            key: 'stock_all',
            align: 'center',
          },
        ],
        goods_setted: [],
        plan_num: '',
      },

      all_status: [
        {
          "id": 0,
          "name": '待审核',
        },
        {
          "id": 1,
          "name": '已审核',
        },
        {
          "id": 2,
          "name": '进行中',
        },
        {
          "id": 3,
          "name": '已完成',
        },
      ],
      clientFormItem: {
        id: '',
        main_no: '',
        name: '',
        expect_finish_time: '',
        remark: '',
        goods_setted: [],
        goods_columns: [
          {
            title: '货品编号',
            key: 'part_no',
            slot: 'part_no',
            align: 'center',
          },
          {
            title: '缩略图',
            key: 'thumbnail_url',
            slot: 'thumbnail_url',
            align: 'center',
          },
          {
            title: '需求生产数量',
            key: 'plan_num',
            align: 'center',
          },
          {
            title: '可用库存',
            slot: 'stock_all',
            key: 'stock_all',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            slot: 'action',
            align: 'center',
          }
        ],
        doing_goods_columns: [
          {
            title: '货品',
            key: 'part_no',
            slot: 'part_no',
            align: 'center',
          },
          {
            title: '缩略图',
            key: 'thumbnail_url',
            slot: 'thumbnail_url',
            align: 'center',
          },
          {
            title: '需求生产数量',
            key: 'plan_num',
            align: 'center',
          },
          {
            title: '已生产数量',
            key: 'created_num',
            align: 'center',
          },
          {
            title: '已入库数量',
            key: 'in_num',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            slot: 'action',
            align: 'center',
            width: 210
          }
        ],
      },

      check_order: {
        title: '查看订单信息',
        modal: false,
      },

      //创建
      add_edit: {
        modal: false,
        model_title: '创建需求信息',
      },

      modal_loading: {
        success: false,
        add_edit: false,
      },

      //列表
      list: {
        columns: [
          {
            title: '需求单号',
            key: 'main_no',
            slot: 'main_no',
            align: 'center',
            minWidth: 40,
          },
          {
            title: '需求名称',
            key: 'name',
            align: 'center',
          },
          {
            title: '预计完成时间',
            key: 'expect_finish_time',
            align: 'center',
          },
          {
            title: '状态',
            key: 'status',
            slot: 'status',
            align: 'center',
          },
          {
            title: '需求进度',
            key: 'progress_rate',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
            minWidth: 60,
          }
        ],
        data: [],
        total: 0,
        size: 0,
      },
      ruleInline: {
        name: [
          {required: true, message: '请填写需求名称'}
        ],
      },

      searchFlag: 0,
    };
  },
  methods: {
    minus(a, b) {
      return NP.minus(a, b)
    },
    getStatusColor(index) {
      let rs = '';
      switch (index) {
        case 0:
          rs = 'color:#ed4014';
          break;
        case 1:
          rs = 'color:#19be6b';
          break;
        case 2:
          rs = 'color:#2775ff';
          break;
        case 3:
          rs = 'color:#2db7f5';
          break;
        case 4:
          rs = 'color:#c5c8ce';
          break;
        case 5:
          rs = 'color:#ff9900';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },

    getSaleTypeColor(index) {
      //获取不同配色
      return index == 2 ? 'color:#ff9900' : '';
    },
    getPayTypeColor(index) {
      //获取不同配色
      return index == 2 ? 'color:#ff9900' : '';
    },

    //领料弹框显示
    showPicking(row) {
      this.picking.goods_id = row.id;
      this.picking.plan_num = row.plan_num;
      this.picking.modal = true;
    },
    //关闭领料窗口
    handleResetPickModal() {
      this.picking.modal = false;
    },
    //关闭领料申请窗口
    handleResetPickViewModal() {
      this.picking_view.modal = false;
    },
    //领料申请详细查看
    applyGoodsView() {
      this.picking_view.modal = true;
      this.$axios.get('/api/create/getBomDetailByGoodsId?id=' + this.picking.goods_id)
          .then((response) => {
            if (response.data.err_no == 0) {
              let index = 0;
              this.picking_view.plan_num = this.picking.plan_num;
              let $need_num = this.picking_view.plan_num;
              response.data.results.children = this.formatApplyGoodsViewData(index, response.data.results.children, $need_num);
              this.picking_view.goods_setted = response.data.results.children;
            }
          });
    },
    getPickingViewNum() {
      let index = 0;
      this.picking_view.goods_setted = this.formatApplyGoodsViewData(index, this.picking_view.goods_setted, this.picking_view.plan_num);
    },
    //数据格式话
    formatApplyGoodsViewData($index, $data, $need_num) {
      if ($data.length <= 0) {
        return $data;
      }

      for (let i = 0; i < $data.length; i++) {
        $data[i].id = $data[i].id + '-' + $index;
        $data[i].need_num = parseFloat($data[i].number) * $need_num;
        $index++;
        $data[i].children = this.formatApplyGoodsViewData($index, $data[i].children, $data[i].need_num);
      }

      return $data;
    },

    // 去除已选择的货品
    deleteGoodsSelected(row, index) {
      this.clientFormItem.goods_setted.splice(index, 1);
    },

    //添加货品
    addGoods(goods_type) {
      this.$refs.select_goods.select_goods.goods_type = goods_type;
      this.$refs.select_goods.showGoodsList();
    },

    //设置选中的货品
    getSelectedGoods(val) {
      let selected_goods = val;
      let setted_goods = this.clientFormItem.goods_setted;

      if (selected_goods.length) {
        for (let i = 0, len = selected_goods.length; i < len; i++) {
          let added_flag = false;

          //排除已添加的
          for (let j = 0, jlen = setted_goods.length; j < jlen; j++) {
            if (setted_goods[j].id == selected_goods[i].id) {
              added_flag = true;
              break;
            }
          }

          //如果未添加
          if (!added_flag) {
            selected_goods[i].plan_num = '';
            setted_goods.push(selected_goods[i]);
          }
        }
      }

    },
    handleReset(name) {
      this.$refs[name].resetFields();
      //弹出框重置
      this.add_edit.modal = false;
      this.modal_loading.add_edit = false;
    },
    create(name) {
      //创建货品信息

      this.add_edit.modal = true;
      this.modal_loading.add_edit = false;
      this.$refs[name].resetFields();

      this.$axios.get('/api/create/getProjectMainNo')
          .then((response) => {
            if (response.data.err_no == 0) {
              this.clientFormItem.main_no = response.data.results;
              this.clientFormItem.goods_setted = [];
            }
          });
    },

    listEdit(row) {

      this.getCreatePlanById(row);

      this.add_edit.model_title = '修改需求订单信息';
      this.add_edit.modal = true;
      this.modal_loading.add_edit = false;
    },

    //获取需求单信息
    getCreatePlanById(row) {
      this.$axios.get('/api/create/getCreatePlanById?id=' + row.id)
          .then((response) => {
            if (response.data.err_no == 0) {

              this.clientFormItem.id = row.id;
              this.clientFormItem.main_no = row.main_no;
              this.clientFormItem.status = row.status;
              this.clientFormItem.name = row.name;
              this.clientFormItem.expect_finish_time = row.expect_finish_time;
              this.clientFormItem.finish_time = row.finish_time;
              this.clientFormItem.remark = row.remark;

              this.clientFormItem.goods_setted = response.data.results.goods_setted;
            }
          });
    },

    //删除操作提示
    listDeleteAlert(row) {
      this.$Modal.confirm({
        title: "删除需求信息",
        content: '确定删除需求信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.deleteListInfo(row.id);
        },
        onCancel: () => {
        }
      });
    },

    //删除订单
    deleteListInfo($id) {
      let param = {
        id: $id,
      };
      this.$axios.post('/api/create/deleteCreatePlan', param)
          .then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.getPlanList();
              this.check_order.modal = false;
            } else {
              this.$Message.error(response.data.err_msg);
            }
          });
    },

    setNum(row, index) {
      if (isNaN(row.plan_num)) {
        this.clientFormItem.goods_setted[index].plan_num = 0;
      } else {
        //赋值
        this.clientFormItem.goods_setted[index].plan_num = row.plan_num;
      }
    },

    //创建需求
    createCreateProject(name) {
      this.modal_loading.add_edit = true;

      this.$refs[name].validate((valid) => {
        if (valid) {
          //判断是否添加了需求货品
          let goods_setted = [];
          if (this.clientFormItem.goods_setted.length < 1) {
            this.$Message.error('请添加需求货品');
            this.modal_loading.add_edit = false;
            return;
          } else {
            //过滤无效数据
            for (let i = 0; i < this.clientFormItem.goods_setted.length; i++) {
              let tmp = {
                goods_id: '',
                plan_num: 0,
              };
              tmp.goods_id = this.clientFormItem.goods_setted[i].id;
              tmp.plan_num = this.clientFormItem.goods_setted[i].plan_num;

              if (tmp.plan_num <= 0) {
                this.$Message.error('需求生产数量不能小于等于0');
                this.modal_loading.add_edit = false;
                return;
              }

              goods_setted[i] = tmp;
            }
          }

          let expect_finish_time = '';
          if (this.clientFormItem.expect_finish_time) {
            expect_finish_time = this.dateFormat(this.clientFormItem.expect_finish_time);
          }

          let param = {
            id: this.clientFormItem.id,
            main_no: this.clientFormItem.main_no,
            name: this.clientFormItem.name,
            remark: this.clientFormItem.remark,
            logistics_id: this.clientFormItem.logistics_id,
            expect_finish_time: expect_finish_time,
            goods_setted: goods_setted,
          };
          this.$axios.post('/api/create/createProject', param)
              .then((response) => {
                if (response.data.err_no == 0) {
                  this.$Message.success(response.data.results);
                  this.getPlanList();
                  this.add_edit.modal = false;
                } else {
                  this.$Message.error(response.data.err_msg);
                  this.modal_loading.add_edit = false;
                }

              });
        } else {
          this.modal_loading.add_edit = false;
        }
      });
    },
    searchSubmit() {
      this.searchFlag = 1;
      this.getPlanList();
    },
    cancelSearch() {
      this.searchFlag = 0;
      this.formItem.main_no = '';
      this.formItem.status = '';
      this.formItem.created_at = '';
      this.getPlanList();
    },

    //时间格式化函数，此处仅针对yyyy-MM-dd hh:mm:ss 的格式进行格式化
    dateFormat: function (time) {
      let date = new Date(time);
      let year = date.getFullYear();
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // 拼接
      return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
    },

    //获取需求单列表
    getPlanList($page = 1, $main_no = '', $status = '', $created_at = '') {
      if (this.searchFlag) {
        $main_no = this.formItem.main_no;
        $status = this.formItem.status;
        $created_at = this.formItem.created_at;
      }
      let param = {
        params: {
          page: $page,
          main_no: $main_no,
          status: $status,
          created_at: $created_at,
        }
      };
      this.$axios.get('/api/create/getPlanList', param)
          .then((response) => {
            if (response.data.err_no == 0) {
              this.list.data = response.data.results.list;
              this.list.total = response.data.results.total;
              this.list.size = response.data.results.size;
            }
          });
    },
    changePage(page) {
      this.getPlanList(page);
    },

    //打开审核页面
    checkCreatePlan(row) {
      //操作需求时，货品栏目会有变化
      if (row.status == 2) {
        //需求进行中时
        this.clientFormItem.goods_columns = this.clientFormItem.doing_goods_columns;
      }

      this.getCreatePlanById(row);

      this.check_order.modal = true;
      this.modal_loading.success = false;
    },

    //显示订单信息
    showEditSaleOrder(clientFormItem) {
      this.listEdit(clientFormItem);
      this.check_order.modal = false;
    },

    //需求订单审核通过
    passCreatePlan(clientFormItem) {
      this.modal_loading.success = true;

      let param = {
        id: clientFormItem.id,
      };

      //判断货品是否有bom结构
      for (let i = 0; i < clientFormItem.goods_setted.length; i++) {
        if (clientFormItem.goods_setted[i].bom_flag == 0) {
          this.$Message.error('货品<span style="color:#2775ff;">' + clientFormItem.goods_setted[i].part_no + '</span>的BOM结构未完善，请先完善BOM结构');
          this.modal_loading.success = false;
          return;
        }
      }

      this.$axios.post('/api/create/passCreatePlan', param)
          .then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.getPlanList();
              this.check_order.modal = false;
            } else {
              this.$Message.error(response.data.err_msg);
              this.modal_loading.success = false;
            }
          });
    },

    //开始需求
    startCreatePlan(clientFormItem) {
      this.modal_loading.success = true;

      let param = {
        id: clientFormItem.id,
      };

      this.$axios.post('/api/create/startCreatePlan', param)
          .then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.getPlanList();
              this.check_order.modal = false;
            } else {
              this.$Message.error(response.data.err_msg);
              this.modal_loading.success = false;
            }
          });
    },

    //审核弹出框重置
    handleResetOrderModal(item) {
      this.check_order.modal = false;
      item.modal = false;
    },

    timeChange(dateRange) {
      if (dateRange[0] && dateRange[1]) {
        this.formItem.created_at = dateRange;
      } else {
        this.formItem.created_at = "";
      }
    },
  },
  mounted() {
    this.getPlanList();
  },
  components: {
    SelectGoods,
  }
};
</script>

<style scoped>
#clientInfo {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

#panel-header {
  position: relative;
  border: none;
}

.modalForm {
  width: 100%;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

.client_create {
  position: absolute;
  right: 0;
  top: -8px;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#list .page {
  margin-top: 16px;
}

#clientInfo .ivu-card {
  background-color: #fafafa;
}

#clientInfo .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#clientInfo .ivu-form-item {
  margin-bottom: 0;
}

#list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#list .ivu-page {
  float: right;
}

</style>
<style>
.list .ivu-table-overflowX, .ivu-table-tip {
  overflow-x: hidden;
}

.list .ivu-table-wrapper {
  overflow: visible;
}

</style>
