<template>
  <div id="clientInfo">
    <div id="panel-header">
      <span class="top-title">生产计划</span>
      <Button type="primary" class="client_create" v-if="aCheck.rightCheck('edit_create_plan')" @click="createPlan('clientFormItem')">添加计划</Button>
    </div>
    <!--列表筛选条件-->
    <div id="filter-bar">
      <Card dis-hover>
        <Form ref="list_search_item" inline>
          <FormItem prop="main_no">
            <i-input @on-blur="searchSubmit" v-model="formItem.main_no" class="w-200" placeholder="请填写计划编号"/>
          </FormItem>

          <FormItem prop="status">
            <Select @on-change="searchSubmit" v-model="formItem.status"class="w-200" placeholder="请选择计划状态" filterable>
              <Option v-for="item in all_status" :value="item.id" :key="item.id">
                {{ item.name }}
              </Option>
            </Select>
          </FormItem>

          <FormItem prop="created_at">
            <DatePicker class="w-200" type="daterange" @on-change="timeChange" placement="bottom-end"
                       :value="formItem.created_at" placeholder="请选择预计完成时间"></DatePicker>
          </FormItem>

          <FormItem>&nbsp;
            <Button type="text" class="main-font-color" @click="cancelSearch('list_search_item')">清除</Button>
          </FormItem>

        </Form>
      </Card>
    </div>

    <!--列表-->
    <div id="list" class="list">
      <Table :columns="list.columns" :data="list.data">
        <template slot-scope="{ row }" slot="main_no">
          <strong @click="checkCreatePlan(row)" class="pointer">{{ row.main_no }}</strong>
        </template>
        <template slot-scope="{ row }" slot="status">
          <span :style="getStatusColor(row.status)" v-for="item in all_status"
                v-show="item.id==row.status">{{ item.name }}</span>
        </template>

        <template slot-scope="{ row }" slot="finish_time">
          <span>{{row.finish_time.substring(0, 16)}}</span>
        </template>

        <template slot-scope="{ row }" slot="progress_rate">
          <Progress :class="row.status==5 ?'grey-bg-color':''" :percent="npTimes(row.progress_rate, 100)" />
        </template>


        <template slot-scope="{ row, index }" slot="action">
          <!--待审核情况下显示-->
          <Button v-show="row.status==1" type="text" size="small" :style="getStatusColor(row.status)" v-if="aCheck.rightCheck('valid_create_plan')"
                  @click="checkCreatePlan(row)">
            <Icon type="ios-checkmark-circle"/>
            审核
          </Button>

          <Button v-show="row.status==2" type="text" size="small" :style="getStatusColor(row.status)" v-if="aCheck.rightCheck('operate_create_plan')"
                  @click="startCreatePlan(row)">
            <Icon type="ios-checkmark-circle"/>
            开始计划
          </Button>

          <Button v-show="row.status==3" type="text" size="small" :style="getStatusColor(row.status)" v-if="aCheck.rightCheck('operate_create_plan')"
                  @click="checkCreatePlan(row)">
            <Icon type="ios-checkmark-circle"/>
            操作计划
          </Button>

          <Button v-show="[4,5].includes(row.status)" type="text" size="small" :style="getStatusColor(row.status)"
                  @click="checkCreatePlan(row)">
            <Icon type="ios-checkmark-circle"/>
            查看
          </Button>

          <Dropdown v-show="row.status==1" v-if="aCheck.rightCheck('edit_create_plan')">
            <Button size="small" type="text">
              更多
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem v-show="row.status==1">
                <span @click="listEdit(row)" class="f12">修改计划</span>
              </DropdownItem>
              <DropdownItem v-show="row.status==1">
                <span @click="listDeleteAlert(row)" class="f12">删除计划</span>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </template>
      </Table>
      <div class="page">
        <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"/>
      </div>

    </div>

    <!--添加修改计划-->
    <Modal v-model="add_edit.modal" :title="add_edit.model_title" width="1080">
      <div class="modalForm">
        <Form ref="clientFormItem" :model="clientFormItem" :label-width="100" :rules="ruleInline" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="计划编号" prop="main_no">
                <Input v-model="clientFormItem.main_no" disabled placeholder="请填写计划编号"></Input>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="计划名称" prop="name">
                <Input v-model="clientFormItem.name" placeholder="请填写计划名称"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="预计完成时间" prop="expect_finish_time">
                <DatePicker type="date"  show-week-numbers @on-change="setExpectFinishDate"  placeholder="日期选择" style="width: 100%" format="yyyy-MM-dd"></DatePicker>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="备注" prop="remark">
                <Input v-model="clientFormItem.remark" placeholder="请填写备注信息"></Input>
              </FormItem>
            </Col>
          </Row>

          <Row class="mt2 display-block">
            <Card dis-hover>
              <p slot="title">计划明细</p>
              <Button type="primary" slot="extra" size="small" @click="addGoods('2,3')" class="fs-12">
                <Icon type="md-add"/>选择货品
              </Button>
              <Table :columns="clientFormItem.goods_columns" :data="clientFormItem.goods_setted">
                <template slot-scope="{ row, index }" slot="part_no">
                  <span>({{ row.part_no }}) {{ row.part_name }}</span>&nbsp;
                </template>

                <template slot-scope="{ row }" slot="thumbnail_url">
                  <p v-show="row.thumbnail_url"><img :src="row.thumbnail_url" alt="" class="preview-img"></p>
                </template>

                <template slot-scope="{ row, index }" slot="plan_num">
                    <i-input type="number" v-model="row.plan_num" @on-change="setNum(row, index)"/>
                </template>

                <template slot-scope="{ row }" slot="stock_all">
                  <span>{{ minus(row.stock_all, row.frozen_stock) }}</span>
                </template>

                <template slot-scope="{ row, index }" slot="action">
                  <Button type="text" class="fs-12" @click="deleteGoodsSelected(row, index)">
                    <Icon type="ios-trash"/>删除
                  </Button>
                </template>
              </Table>
            </Card>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleReset('clientFormItem')">取消</Button>
        <Button type="primary" :loading="modal_loading.add_edit" @click="createCreateProject('clientFormItem')">
          保存
        </Button>
      </div>
    </Modal>

    <!--查看-->
    <Modal v-model="check_order.modal" :title="check_order.title" width="1080">
      <div class="modalForm">
        <Form :model="clientFormItem" :label-width="120" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="计划单号">
                <span>{{ clientFormItem.main_no }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="计划名称">
                <span>{{ clientFormItem.name }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="预计完成时间">
                <span>{{ clientFormItem.expect_finish_time}}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="备注">
                <span>{{ clientFormItem.remark }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="计划状态">
                <span :style="getStatusColor(clientFormItem.status)" v-for="item in all_status"
                      v-show="item.id==clientFormItem.status">{{ item.name }}</span>
              </FormItem>
            </Col>
          </Row>

          <Row class="mt2 display-block">
            <Card :bordered="false">
              <p slot="title">计划详情</p>
              <Table :columns="clientFormItem.columns" :data="clientFormItem.goods_setted">
                <template slot-scope="{ row, index }" slot="part_no">
                  <span>({{ row.part_no }}) {{ row.part_name }}</span>&nbsp;
                  <code v-if="!row.bom_flag" class="fs-12">暂无BOM</code>
                </template>

                <template slot-scope="{ row }" slot="thumbnail_url">
                  <p><img v-show="row.thumbnail_url" :src="row.thumbnail_url" alt="" class="preview-img"></p>
                </template>

                <template slot-scope="{ row }" slot="stock_all">
                  <span>{{ minus(row.stock_all, row.frozen_stock) }}</span>
                </template>

                <template slot-scope="{ row }" slot="plan_num">
                  <span>{{ row.plan_num }}</span>
                </template>

                <template slot-scope="{ row, index }" slot="action">
                  <Button type="text" size="small" v-show="clientFormItem.status==3" v-if="aCheck.rightCheck('operate_create_plan')"
                          class="button-in-text-blue" @click="showPicking(row)">
                    <Icon type="ios-walk"/>
                    领料
                  </Button>

                  <a :href="'/CreateProjectDetailView?plan_id='+clientFormItem.id+'&goods_id='+row.id" target="_blank">
                    <Button type="text" size="small" v-show="[3,4].includes(clientFormItem.status)"
                                     class="button-in-text-blue">
                      <Icon type="ios-apps" />
                      用料明细
                    </Button>
                  </a>

                  <Button type="text" size="small" v-show="clientFormItem.status==3" v-if="aCheck.rightCheck('operate_create_plan')"
                          class="button-in-text-blue" @click="turnToCreate(clientFormItem.id, row.id, row.plan_num, row.created_num)">
                    <Icon type="ios-hammer"/>
                    转生产
                  </Button>
                </template>
              </Table>
            </Card>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button type="primary" v-show="[2,3].includes(clientFormItem.status)" :loading="modal_loading.success" v-if="aCheck.rightCheck('operate_create_plan')"
                @click="closeCreatePlan(clientFormItem)">关闭计划
        </Button>

        <Button @click="handleResetOrderModal(clientFormItem)">取消</Button>
        <Button type="primary" v-show="clientFormItem.status==1" @click="showEditSaleOrder(clientFormItem)" v-if="aCheck.rightCheck('edit_create_plan')">
          修改订单
        </Button>
        <Button type="success" v-show="clientFormItem.status==1" :loading="modal_loading.success" v-if="aCheck.rightCheck('valid_create_plan')"
                @click="passCreatePlan(clientFormItem)">审核通过
        </Button>

        <Button type="success" v-show="clientFormItem.status==2" :loading="modal_loading.success" v-if="aCheck.rightCheck('operate_create_plan')"
                @click="startCreatePlan(clientFormItem)">开始计划
        </Button>

      </div>
    </Modal>

    <!--转生产数量-->
    <Modal v-model="create.modal" title="转生产操作" width="600">
      <Form :label-width="120" label-colon>
        <Row :gutter="20">
          <Col span="24">
            <FormItem label="生产数量" prop="plan_num">
              <Input v-model="create.data.plan_num" type="number" placeholder="请填写生产数量"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="20">
          <Col span="24">
            <FormItem label="生产开始日期" prop="create_start_date">
              <DatePicker :value="create.data.create_start_date" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                          placeholder="请填写生产时间(不填，默认当前时间)" style="width: 448px" @on-change="getStartDateTime"></DatePicker>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="20">
          <Col span="24">
            <FormItem label="生产结束日期" prop="create_end_date">
              <DatePicker :value="create.data.create_end_date" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                          placeholder="请填写生产时间(不填，默认当前时间)" style="width: 448px" @on-change="getEndDateTime"></DatePicker>
            </FormItem>
          </Col>
        </Row>
      </Form>

      <div slot="footer">
        <Button @click="closeCreateModal">取消</Button>
        <Button type="primary" :loading="create.modal_loading" @click="createOperate">
          保存
        </Button>
      </div>
    </Modal>

    <!--领料弹框-->
    <Modal v-model="picking.modal" :title="picking.title" width="1080">
<!--      <Row>-->
<!--        <Card :bordered="false">-->
<!--          <p slot="title">领料详情</p>-->
<!--          <Table :columns="picking.goods_columns" :data="picking.goods_setted">-->
<!--            <template slot-scope="{ row, index }" slot="part_no">-->
<!--              <span>({{ row.part_no }}) {{ row.part_name }}</span>&nbsp;-->
<!--              <code v-if="!row.bom_flag">暂无BOM</code>-->
<!--            </template>-->

<!--            <template slot-scope="{ row }" slot="thumbnail_url">-->
<!--              <p><img :src="row.thumbnail_url" alt="" class="preview-img" v-show="row.thumbnail_url"></p>-->
<!--            </template>-->

<!--            <template slot-scope="{ row }" slot="stock_all">-->
<!--              <span>{{ row.stock_all - row.frozen_stock }}</span>-->
<!--            </template>-->
<!--          </Table>-->
<!--        </Card>-->
<!--      </Row>-->

      <Row class="display-block">
        <Card :bordered="false">
          <p slot="title">领料申请记录</p>
          <p slot="extra">
            <Button type="text" size="small" class="button-in-text-blue" @click="applyGoodsView">
              领料申请
            </Button>
          </p>
          <Table :columns="picking.log_columns" :data="picking.apply_log_list">
            <template slot-scope="{ row, index }" slot="status">
              <span :class="getApplyPickStatus(row.status)">{{ getApplyPickStatusText(row.status) }}</span>&nbsp;
            </template>
          </Table>
        </Card>
      </Row>
      <div slot="footer">
        <Button @click="handleResetPickModal">取消</Button>
      </div>
    </Modal>

    <!--领料申请弹框-->
    <Modal v-model="picking_view.modal" :title="picking_view.title" width="1080">
      <Row>
        <span>货品生产数为    <Input style="width:60px;" v-model="picking_view.plan_num" size="small"
                                       placeholder="货品生产数" @on-blur="getPickingViewNum"/>，需要原材料数量如下：</span>
      </Row>
      <Row class="mt10 display-block" >
        <Table :columns="picking_view.goods_columns" :data="picking_view.goods_setted" row-key="id">
          <template slot-scope="{ row }" slot="stock_all">
            <span>{{minus(row.stock_all, row.frozen_stock)}}</span>
          </template>

          <template slot-scope="{ row }" slot="goods_type">
            <span>{{getGoodsType(row.goods_type)}}</span>
          </template>

          <template slot-scope="{ row, index }" slot="need_num">
            <i-input type="number" v-model="picking_view.goods_setted[index].need_num"/>
          </template>
        </Table>
      </Row>
      <div slot="footer">
        <Button @click="handleResetPickViewModal">取消</Button>
        <Button type="primary" :loading="picking_view.modal_loading" @click="applyGoodsViewAction">申请</Button>
      </div>
    </Modal>

    <SelectGoods ref="select_goods" @selected="getSelectedGoods" :select_goods="select_goods"/>

  </div>


</template>

<script>
import {getGoodsType} from '@/utils/common'
import SelectGoods from '@/components/common/SelectGoods.vue';
import NP from "number-precision";
import {apiSaveToCreateOperate, apiCloseCreatePlan} from "@/api/create/createPlan";

export default {
  name: "GoodsCreatePlan",
  data() {
    return {
      select_goods: {
        modal: false,
        count_flag: 0,
        goods_type: 0
      },

      formItem: {
        main_no: '',
        status: '',
        created_at: '',
      },

      create: {
        modal_loading: false,
        modal: false,
        data: {
          plan_id: '',
          goods_id: '',
          plan_num: '',
          create_start_date: '',
          create_end_date: '',
        }
      },

      picking: {
        modal: false,
        goods_id: '',
        title: '计划领料信息',
        goods_columns: [
          {
            title: '货品',
            key: 'part_no',
            slot: 'part_no',
            align: 'center',
          },
          {
            title: '缩略图',
            key: 'thumbnail_url',
            slot: 'thumbnail_url',
            align: 'center',
          },
          {
            title: '总领料数',
            key: 'plan_num',
            align: 'center',
          },
          {
            title: '已使用',
            key: 'created_num',
            align: 'center',
          },
          {
            title: '剩余',
            key: 'in_num',
            align: 'center',
          },
        ],
        log_columns: [
          {
            title: '领料单号',
            key: 'main_no',
            align: 'center',
          },
          {
            title: '记录状态',
            key: 'status',
            slot: 'status',
            align: 'center',
          },
          {
            title: '操作员',
            key: 'operator_name',
            align: 'center',
          },
          {
            title: '操作时间',
            key: 'updated_at',
            align: 'center',
          },
        ],
        plan_id: '',
        apply_log_list: [],
      },

      picking_view: {
        modal: false,
        title: '领料申请信息',
        goods_columns: [
          {
            title: '货品编号',
            align: 'center',
            key: 'part_no',
          },
          {
            title: '货品名称',
            align: 'center',
            key: 'part_name',
          },
          {
            title: '货品类型',
            key: 'goods_type',
            slot: 'goods_type',
            align: 'center',
          },
          {
            title: '申请领料',
            key: 'need_num',
            slot: 'need_num',
            align: 'center',
          },
          {
            title: '库存',
            key: 'stock_all',
            align: 'center',
          },
        ],
        goods_setted: [],
        plan_num: '',
        modal_loading: false,
      },

      all_status: [
        {
          "id": 1,
          "name": '待审核',
        },
        {
          "id": 2,
          "name": '已审核',
        },
        {
          "id": 3,
          "name": '进行中',
        },
        {
          "id": 4,
          "name": '已完成',
        },
        {
          "id": 5,
          "name": '已关闭',
        },
      ],
      clientFormItem: {
        id: '',
        main_no: '',
        name: '',
        expect_finish_time: '',
        remark: '',
        status: '',
        goods_setted: [],
        columns:[],
        goods_columns: [
          {
            title: '货品编号',
            key: 'part_no',
            slot: 'part_no',
            width: 240,
            align: 'center',
          },
          {
            title: '缩略图',
            key: 'thumbnail_url',
            slot: 'thumbnail_url',
            align: 'center',
          },
          {
            title: '计划生产数量',
            key: 'plan_num',
            slot: 'plan_num',
            align: 'center',
          },
          {
            title: '可用库存',
            key: 'stock_all',
            slot: 'stock_all',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            slot: 'action',
            align: 'center',
          }
        ],
        doing_goods_columns: [
          {
            title: '货品',
            key: 'part_no',
            slot: 'part_no',
            width: 240,
            align: 'center',
          },
          {
            title: '缩略图',
            key: 'thumbnail_url',
            slot: 'thumbnail_url',
            align: 'center',
          },
          {
            title: '计划生产数量',
            key: 'plan_num',
            align: 'center',
          },
          {
            title: '已生产数量',
            key: 'created_num',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            slot: 'action',
            align: 'center',
            width: 260
          }
        ],
      },

      check_order: {
        title: '查看订单信息',
        modal: false,
      },

      //创建
      add_edit: {
        modal: false,
        model_title: '创建计划信息',
      },

      modal_loading: {
        success: false,
        add_edit: false,
      },

      //列表
      list: {
        columns: [
          {
            title: '计划单号',
            key: 'main_no',
            slot: 'main_no',
            align: 'center',
            minWidth: 40,
          },
          {
            title: '计划名称',
            key: 'name',
            align: 'center',
          },
          {
            title: '预计完成时间',
            key: 'expect_finish_time',
            align: 'center',
          },
          {
            title: '状态',
            key: 'status',
            slot: 'status',
            align: 'center',
          },
          {
            title: '计划进度',
            key: 'progress_rate',
            slot: 'progress_rate',
            align: 'center',
          },
          {
            title: '完成时间',
            key: 'finish_time',
            slot: 'finish_time',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
            minWidth: 60,
          }
        ],
        data: [],
        total: 0,
        size: 0,
        page: 1,
      },
      ruleInline: {
        name: [
          {required: true, message: '请填写计划名称'}
        ],
      },

    };
  },
  methods: {
    npTimes(a, b) {
      return NP.times(a, b);
    },
    getEndDateTime(value) {
      this.create.data.create_end_date = value;
    },
    getStartDateTime(value) {
      this.create.data.create_start_date = value;
    },
    closeCreateModal() {
      this.create.data.plan_num = '';
      this.create.data.create_start_date = '';
      this.create.data.create_end_date = '';

      this.create.modal = false;
      this.create.modal_loading = false;
    },
    createOperate() {
      this.create.modal_loading = true;

      if (this.create.data.plan_num <= 0) {
        this.$Message.error("生产数量不能小于等于 0");
        this.create.modal_loading = false;
        return;
      }

      apiSaveToCreateOperate(this.create.data).then(res => {
        if (res.data.err_no == 0) {
          this.$Message.success(res.data.results);
          this.create.modal = false;
        } else {
          this.$Message.error(res.data.err_msg);
        }
        this.create.modal_loading = false;
      }).catch(err => {
        this.$Message.error('操作失败！');
        this.create.modal_loading = false;
      });
    },
    turnToCreate(plan_id, goods_id, plan_num, created_num) {
      this.create.data.plan_id = plan_id;
      this.create.data.goods_id = goods_id;
      this.create.data.plan_num = NP.minus(plan_num, created_num);
      this.create.data.main_no = this.clientFormItem.main_no;
      this.create.modal = true;
    },
    setExpectFinishDate(value) {
      this.clientFormItem.expect_finish_time = value;
    },
    getGoodsType(goods_type) {
      return getGoodsType(goods_type);
    },
    minus(a, b) {
      return NP.minus(a, b)
    },
    getApplyPickStatusText(status) {
      let rs = '';
      switch (status) {
        case 0:
          rs = '待审核';
          break;
        case 1:
          rs = '待领料';
          break;
        case 2:
          rs = '领料中';
          break;
        case 3:
          rs = '已出库';
          break;
        case 4:
          rs = '已领料';
          break;
        case 5:
          rs = '领料退库';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getApplyPickStatus(status) {
      let rs = '';
      switch (status) {
        case 0:
          rs = 'status-danger';
          break;
        case 1:
          rs = 'status-main';
          break;
        case 2:
          rs = 'status-info';
          break;
        case 3:
          rs = 'status-normal';
          break;
        case 4:
          rs = 'status-success';
          break;
        case 5:
          rs = 'status-main';
          break;
      }

      return rs;
    },
    getStatusColor(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = 'color:#ed4014';
          break;
        case 2:
          rs = 'color:#19be6b';
          break;
        case 3:
          rs = 'color:#2db7f5';
          break;
        case 4:
          rs = 'color:#2775ff';
          break;
        case 5:
          rs = 'color:rgb(197, 200, 206)';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },

    getSaleTypeColor(index) {
      //获取不同配色
      return index == 2 ? 'color:#ff9900' : '';
    },
    getPayTypeColor(index) {
      //获取不同配色
      return index == 2 ? 'color:#ff9900' : '';
    },

    //领料弹框显示
    showPicking(row) {
      this.picking.goods_id = row.id;
      this.picking.plan_num = row.plan_num;
      this.picking.plan_id = this.clientFormItem.id;
      this.picking.plan_no = this.clientFormItem.main_no;

      this.getPickLogList(this.picking.plan_id);
      this.picking.modal = true;
    },
    //关闭领料窗口
    handleResetPickModal() {
      this.picking.modal = false;
    },
    //关闭领料申请窗口
    handleResetPickViewModal() {
      this.picking_view.modal = false;
    },
    //领料申请详细查看
    applyGoodsView() {
      this.picking_view.modal_loading = false;
      this.picking_view.modal = true;
      this.$axios.get('/api/create/getBomDetailByGoodsId?id=' + this.picking.goods_id)
          .then((response) => {
            if (response.data.err_no == 0) {
              let index = 0;
              this.picking_view.plan_num = this.picking.plan_num;
              let $need_num = this.picking_view.plan_num;
              response.data.results.children = this.formatApplyGoodsViewData(index, response.data.results.children, $need_num);
              this.picking_view.goods_setted = response.data.results.children;

            }
          });
    },
    applyGoodsViewAction() {
      this.picking_view.modal_loading = true;
      //领料申请动作
      let data = [];
      for (let i = 0; i < this.picking_view.goods_setted.length; i++) {
        data[i] = {goods_id: '', need_num: 0};
        data[i].goods_id = this.picking_view.goods_setted[i].goods_id;
        data[i].need_num = this.picking_view.goods_setted[i].need_num;

        //库存判断，如果库存不够不能领料
        if (this.picking_view.goods_setted[i].need_num > (this.picking_view.goods_setted[i].stock_all - this.picking_view.goods_setted[i].frozen_stock)) {
          this.$Message.error(this.picking_view.goods_setted[i].part_no + '库存不足，无法申请领料');
          this.picking_view.modal_loading = false;
          return;
        }

        if (this.picking_view.goods_setted[i].need_num < 0) {
          this.$Message.error(this.picking_view.goods_setted[i].part_no + '申请领料数不能小于0');
          this.picking_view.modal_loading = false;
          return;
        }
      }

      let param = {
        goods_info: data,
        plan_id: this.picking.plan_id,
        plan_no: this.picking.plan_no,
      };
      this.$axios.post('/api/create/goodsApply', param)
          .then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.getPlanList();
              this.getPickLogList(this.picking.plan_id);
              this.picking_view.modal = false;
              this.picking_view.modal_loading = false;
            } else {
              this.$Message.error(response.data.err_msg);
              this.picking_view.modal_loading = false;
            }
          })
          .catch((error) => {
            this.$Message.error('操作失败');
            this.picking_view.modal_loading = false;
          });

    },
    getPickingViewNum() {
      let index = 0;
      this.picking_view.goods_setted = this.formatApplyGoodsViewData(index, this.picking_view.goods_setted, this.picking_view.plan_num);
    },
    //数据格式话
    formatApplyGoodsViewData($index, $data, $need_num) {
      if ($data.length <= 0) {
        return $data;
      }

      for (let i = 0; i < $data.length; i++) {
        $data[i].goods_id = $data[i].id;
        $data[i].id = $data[i].id + '-' + $index;
        $data[i].need_num = parseFloat($data[i].number) * $need_num;
        $index++;
        $data[i].children = this.formatApplyGoodsViewData($index, $data[i].children, $data[i].need_num);
      }

      return $data;
    },

    // 去除已选择的货品
    deleteGoodsSelected(row, index) {
      this.clientFormItem.goods_setted.splice(index, 1);
    },

    //添加货品
    addGoods(goods_type) {
      this.$refs.select_goods.select_goods.goods_type = goods_type;
      this.$refs.select_goods.showGoodsList();
    },

    //设置选中的货品
    getSelectedGoods(val) {
      let selected_goods = val;
      let setted_goods = this.clientFormItem.goods_setted;

      if (selected_goods.length) {
        for (let i = 0, len = selected_goods.length; i < len; i++) {
          let added_flag = false;

          //排除已添加的
          for (let j = 0, jlen = setted_goods.length; j < jlen; j++) {
            if (setted_goods[j].id == selected_goods[i].id) {
              added_flag = true;
              break;
            }
          }

          //如果未添加
          if (!added_flag) {
            selected_goods[i].plan_num = '';
            setted_goods.push(selected_goods[i]);
          }
        }
      }

    },
    handleReset(name) {
      this.$refs[name].resetFields();
      //弹出框重置
      this.add_edit.modal = false;
      this.modal_loading.add_edit = false;
    },
    createPlan(name) {
      //创建货品信息
      this.add_edit.modal = true;
      this.modal_loading.add_edit = false;
      this.$refs[name].resetFields();

      this.clientFormItem.columns = this.clientFormItem.goods_columns;

      this.$axios.get('/api/create/getProjectMainNo')
          .then((response) => {
            if (response.data.err_no == 0) {
              this.clientFormItem.main_no = response.data.results;
              this.clientFormItem.goods_setted = [];
            }
          });
    },

    listEdit(row) {
      this.clientFormItem.columns = this.clientFormItem.goods_columns;
      this.getCreatePlanById(row);

      this.add_edit.model_title = '修改计划订单信息';
      this.add_edit.modal = true;
      this.modal_loading.add_edit = false;
    },

    //获取计划单信息
    getCreatePlanById(row) {
      this.$axios.get('/api/create/getCreatePlanById?id=' + row.id).then((res) => {
        if (res.data.err_no == 0) {
          this.clientFormItem.id = row.id;
          this.clientFormItem.main_no = row.main_no;
          this.clientFormItem.status = row.status;
          this.clientFormItem.name = row.name;
          this.clientFormItem.expect_finish_time = row.expect_finish_time;
          this.clientFormItem.finish_time = row.finish_time;
          this.clientFormItem.remark = row.remark;

          for (let i=0; i<res.data.results.goods_setted.length; i++) {
            res.data.results.goods_setted[i].plan_num = parseFloat(res.data.results.goods_setted[i].plan_num);
            res.data.results.goods_setted[i].created_num = parseFloat(res.data.results.goods_setted[i].created_num);
          }
          this.clientFormItem.goods_setted = res.data.results.goods_setted;
        }
      });
    },

    //删除操作提示
    listDeleteAlert(row) {
      this.$Modal.confirm({
        title: "删除计划信息",
        content: '确定删除计划信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.deleteListInfo(row.id);
        },
        onCancel: () => {
        }
      });
    },

    //删除订单
    deleteListInfo($id) {
      let param = {
        id: $id,
      };
      this.$axios.post('/api/create/deleteCreatePlan', param)
          .then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.getPlanList();
              this.check_order.modal = false;
            } else {
              this.$Message.error(response.data.err_msg);
            }
          });
    },

    setNum(row, index) {
      if (isNaN(row.plan_num)) {
        this.clientFormItem.goods_setted[index].plan_num = 0;
      } else {
        //赋值
        this.clientFormItem.goods_setted[index].plan_num = row.plan_num;
      }
    },

    //创建计划
    createCreateProject(name) {
      this.modal_loading.add_edit = true;

      this.$refs[name].validate((valid) => {
        if (valid) {
          //判断是否添加了计划货品
          let goods_setted = [];
          if (this.clientFormItem.goods_setted.length < 1) {
            this.$Message.error('请添加计划货品');
            this.modal_loading.add_edit = false;
            return;
          }

          //过滤无效数据
          for (let i = 0; i < this.clientFormItem.goods_setted.length; i++) {
            let tmp = {
              goods_id: '',
              plan_num: 0,
            };
            tmp.goods_id = this.clientFormItem.goods_setted[i].id;
            tmp.plan_num = this.clientFormItem.goods_setted[i].plan_num;

            if (tmp.plan_num <= 0) {
              this.$Message.error('计划生产数量不能小于等于0');
              this.modal_loading.add_edit = false;
              return;
            }

            goods_setted[i] = tmp;
          }

          let param = {
            id: this.clientFormItem.id,
            main_no: this.clientFormItem.main_no,
            name: this.clientFormItem.name,
            remark: this.clientFormItem.remark,
            logistics_id: this.clientFormItem.logistics_id,
            expect_finish_time: this.clientFormItem.expect_finish_time,
            goods_setted: goods_setted,
          };
          this.$axios.post('/api/create/createProject', param)
              .then((response) => {
                if (response.data.err_no == 0) {
                  this.$Message.success(response.data.results);
                  this.getPlanList();
                  this.add_edit.modal = false;
                } else {
                  this.$Message.error(response.data.err_msg);
                  this.modal_loading.add_edit = false;
                }
              });
        } else {
          this.modal_loading.add_edit = false;
        }
      });
    },
    searchSubmit() {
      this.list.page = 1;
      this.getPlanList();
    },
    cancelSearch() {
      this.formItem.main_no = '';
      this.formItem.status = '';
      this.formItem.created_at = [];
      this.list.page = 1;
      this.getPlanList();
    },

    //领料记录
    getPickLogList(plan_id) {
      this.$axios.get('/api/create/getPickLogList?plan_id=' + plan_id).then((res) => {
        if (res.data.err_no == 0) {
          this.picking.apply_log_list = res.data.results;
        }
      });
    },

    //获取计划单列表
    getPlanList() {
      let param = {
          page: this.list.page,
          main_no: this.formItem.main_no,
          status: this.formItem.status,
          created_at: this.formItem.created_at,
      };
      this.$axios.post('/api/create/getPlanList', param).then((res) => {
        if (res.data.err_no == 0) {
          this.list.data = res.data.results.list;
          this.list.total = res.data.results.total;
          this.list.size = res.data.results.size;
        }
      });
    },
    changePage(page) {
      this.list.page = page
      this.getPlanList();
    },

    //打开审核页面
    checkCreatePlan(row) {
      //操作计划时，货品栏目会有变化
      if ([3,4].includes(row.status)) {
        //计划进行中时
        this.clientFormItem.columns = this.clientFormItem.doing_goods_columns;
      } else {
        this.clientFormItem.columns = this.clientFormItem.goods_columns;
      }

      this.getCreatePlanById(row);

      this.check_order.modal = true;
      this.modal_loading.success = false;
    },

    //显示订单信息
    showEditSaleOrder(clientFormItem) {
      this.listEdit(clientFormItem);
      this.check_order.modal = false;
    },

    //计划订单审核通过
    passCreatePlan(clientFormItem) {
      this.modal_loading.success = true;

      let param = {
        id: clientFormItem.id,
      };

      //判断货品是否有bom结构
      for (let i = 0; i < clientFormItem.goods_setted.length; i++) {
        if (clientFormItem.goods_setted[i].bom_flag == 0) {
          this.$Message.error('货品<span style="color:#2775ff;">' + clientFormItem.goods_setted[i].part_no + '</span>的BOM结构未完善，请先完善BOM结构');
          this.modal_loading.success = false;
          return;
        }
      }

      this.$axios.post('/api/create/passCreatePlan', param)
          .then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.getPlanList();
              this.check_order.modal = false;
            } else {
              this.$Message.error(response.data.err_msg);
              this.modal_loading.success = false;
            }
          });
    },
    //关闭计划
    closeCreatePlan(row) {
      this.modal_loading.success = true;

      this.$Modal.confirm({
            title: "关闭计划操作",
            content: '确定要关闭生产计划？',
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
              let param = {
                id: row.id,
                main_no: row.main_no
              };

              apiCloseCreatePlan(param).then(res => {
                if (res.data.err_no == 0) {
                  this.$Message.success(res.data.results);
                  this.getPlanList();
                  this.check_order.modal = false;
                } else {
                  this.$Message.error(res.data.err_msg);
                  this.modal_loading.success = false;
                }
              })
            },
        onCancel: () => {
          this.modal_loading.success = false;
        }
      });


    },

    //开始计划
    startCreatePlan(clientFormItem) {
      this.modal_loading.success = true;

      let param = {
        id: clientFormItem.id,
      };

      this.$axios.post('/api/create/startCreatePlan', param).then((res) => {
        if (res.data.err_no == 0) {
          this.$Message.success(res.data.results);
          this.getPlanList();
          this.check_order.modal = false;
        } else {
          this.$Message.error(res.data.err_msg);
          this.modal_loading.success = false;
        }
      });
    },

    //审核弹出框重置
    handleResetOrderModal(item) {
      this.check_order.modal = false;
      item.modal = false;
    },

    timeChange(dateRange) {
      if (dateRange[0] && dateRange[1]) {
        this.formItem.created_at = dateRange;
      } else {
        this.formItem.created_at = "";
      }

      this.searchSubmit();
    },
  },
  mounted() {
    this.getPlanList();
  },
  created() {
    this.aCheck.plateName = 'create_manage';
  },
  components: {
    SelectGoods,
  }
};
</script>

<style scoped>
#clientInfo {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

#panel-header {
  position: relative;
  border: none;
}

.modalForm {
  width: 100%;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

.client_create {
  position: absolute;
  right: 0;
  top: -8px;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#list .page {
  margin-top: 16px;
}

#clientInfo .ivu-card {
  background-color: #fafafa;
}

#clientInfo .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#clientInfo .ivu-form-item {
  margin-bottom: 0;
}

#list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#list .ivu-page {
  float: right;
}

</style>
<style>
.list .ivu-table-overflowX, .ivu-table-tip {
  overflow-x: hidden;
}

.list .ivu-table-wrapper {
  overflow: visible;
}

.grey-bg-color .ivu-progress-bg {
  background-color: #c5c8ce !important;
}

</style>
