import request from '@/plugins/http'

export function getCreateOperationMainNo() {
    return request.post('/api/create/getCreateOperationMainNo');
}

export function saveCreateOperation(param) {
    return request.post('/api/create/saveCreateOperation', param);
}

export function getCreateList(param) {
    return request.post('/api/create/getCreateList', param);
}

export function getCreateDetail(param) {
    return request.post('/api/create/getCreateDetail', param);
}

export function passCreateOperate(param) {
    return request.post('/api/create/passCreateOperate', param);
}

export function deleteCreateInfo(param) {
    return request.post('/api/create/deleteCreateInfo', param);
}

export function goodsCreateInStock(param) {
    return request.post('/api/create/goodsCreateInStock', param);
}





