<template>
  <div id="main">
    <div id="panel-header">
      <span class="top-title">BOM表</span>
      <Button type="primary" class="client_create" @click="createBom('2,3')" v-if="aCheck.rightCheck('edit_bom_list')">创建BOM结构</Button>
    </div>
    <div id="filter-bar">
      <Card dis-hover>
        <Form ref="list_search_item" :model="filter.data" inline>
          <FormItem prop="part_no">
            <i-input @on-blur="searchSubmit" v-model="filter.data.part_no"  placeholder="请填写货品编号" class="w-200"/>
          </FormItem>

          <FormItem prop="part_name">
            <i-input @on-blur="searchSubmit" v-model="filter.data.part_name"  placeholder="请填写货品名称"
                     class="w-200"/>
          </FormItem>

          <FormItem prop="type">
            <Select @on-change="searchSubmit" v-model="filter.data.type"  placeholder="请选择货品类别" class="w-200">
              <Option v-for="item in allGoodsType" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>

          <FormItem prop="goods_category_id">
            <Select @on-change="searchSubmit" v-model="filter.data.goods_category_id" class="w-200" filterable  placeholder="请选择货品品类">
              <Option v-for="(option, index) in goodsCategoryOptions" :value="option.value" :key="index">{{option.label}}</Option>
            </Select>
          </FormItem>

          <FormItem prop="status">
            <Select @on-change="searchSubmit" v-model="filter.data.status"  placeholder="请选择BOM状态" class="w-200">
              <Option v-for="item in allStatus" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>

          <FormItem>&nbsp;
            <Button type="text" class="main-font-color" @click="cancelSearch('list_search_item')">
              清除
            </Button>
          </FormItem>
        </Form>
      </Card>
    </div>

    <div class="main-body">
      <div v-for="msg in list.data" class="item-body">
<!--        <Col span="6" v-for="msg in item" class="mt10">-->
          <Card class="pointer">
            <div class="bom-body" @click="getBomDetail(msg.id)">
              <div :class="msg.image_url ? 'bom-img-div bg-fff':'bom-img-div'">
                <div class="copy" v-if="aCheck.rightCheck('edit_bom_list')"><span @click.stop.prevent="createByCopy(msg.id)">复制</span></div>
                <div v-show="msg.status==0" class="list-delete list-operate" v-if="aCheck.rightCheck('edit_bom_list')"><span @click.stop.prevent="deleteTreeData(msg.id)">删除</span></div>
                <div v-show="msg.status==0" class="list-valid list-operate" v-if="aCheck.rightCheck('valid_bom_list')"><span @click.stop.prevent="validTree(msg.id)">生效</span></div>
                <div class="goodsCategoryCopy" v-if="aCheck.rightCheck('edit_bom_list')"><span @click.stop.prevent="createGoodsCategoryByCopy(msg.id)">同品类模板复制</span></div>
                <img v-if="msg.image_url" :src="msg.image_url" class="bom-img">

                <span v-if="!msg.image_url">{{ msg.part_name }}</span>
              </div>
              <div class="mt5">
                <span :style="getTypeColor(msg.goods_type)" class="ivu-tag-dot-inner"></span>
                <span>{{ msg.part_name }} ( {{ msg.part_no }} ) </span>
                <code v-if="!msg.status">待生效</code>
              </div>
            </div>
          </Card>
<!--        </Col>-->
      </div>
    </div>
    <div class="page" v-show="list.total">
      <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"/>
    </div>

    <!--添加修改-->
    <Drawer title="BOM结构" :closable="false" v-model="drawer.model" width="720" closable :mask-closable="false">
      <Tree :data="drawer.data" :render="renderContent" class="tree-render"></Tree>
      <div class="drawer-footer" style="display:flex;justify-content: space-between;">
        <div>
          <Button style="margin-right:8px;" type="success" @click="saveTreeDataCategoryBatch">同品类批量保存</Button>
        </div>
        <div>
          <Button style="margin-right:8px;" @click="closeDrawer">取消</Button>
          <Button type="primary" @click="saveTreeData">保存</Button>
        </div>
      </div>
    </Drawer>

    <!--查看-->
    <Drawer title="BOM结构" :closable="false" v-model="drawer.view_model" width="720" closable>
      <Tree :data="drawer.data" :render="ViewRenderContent" class="tree-render"></Tree>
      <div class="drawer-footer">
        <Button style="margin-right:8px;" @click="closeDrawer">取消</Button>
        <Button v-show="drawer.status==0" style="margin-right:8px;" type="error" @click="deleteTreeData(drawer.data.current_id)" v-if="aCheck.rightCheck('edit_bom_list')">删除</Button>
        <Button v-show="drawer.status==0" style="margin-right:8px;" type="primary" @click="editTreeData" v-if="aCheck.rightCheck('edit_bom_list')">修改</Button>
        <Button v-show="drawer.status==0" type="success" @click="validTree(drawer.data.current_id)" v-if="aCheck.rightCheck('valid_bom_list')">生效</Button>
        <Button v-show="drawer.status==1" type="warning" @click="invalidTree(drawer.data.current_id)" v-if="aCheck.rightCheck('valid_bom_list')">失效</Button>
      </div>
    </Drawer>

    <SelectGoods ref="select_goods" @selected="getSelectedGoods" :select_goods="select_goods"/>
  </div>
</template>

<script>
import SelectGoods from '@/components/common/SelectGoods.vue';
import NP from 'number-precision';
import {getGoodsCategoryList} from "@/api/goods/goodsCategory";
import {getBomList, createGoodsCategoryByCopy, saveBomGoodsCategoryBatch, deleteBom} from "@/api/create/bom";

export default {
  name: "BomTable",
  data() {
    return {
      filter: {
        data: {
          part_no: '',
          part_name: '',
          type: '',
          status: '',
          goods_category_id: 0,
        },
      },
      select_goods: {
        modal: false,
        count_flag: 0,
        goods_type: 0
      },
      drawer: {
        model: false,
        view_model: false,
        parent: {
          id: '',
          title: '',
          // expand: true,
          render: (h, {root, node, data}) => {
            return h('span', {
              style: {
                display: 'inline-block',
                width: '100%'
              }
            }, [
              h('span', [
                h('Icon', {
                  props: {
                    type: 'ios-folder-outline'
                  },
                  style: {
                    marginRight: '8px'
                  }
                }),
                h('span', data.title)
              ]),
              h('span', {
                style: {
                  display: 'inline-block',
                  float: 'right',
                  marginRight: '32px'
                }
              }, [
                h('Button', {
                  props: Object.assign({}, this.buttonProps, {
                    icon: 'ios-create',
                    type: 'primary'
                  }),
                  style: {
                    width: '46px',
                    marginRight: '10px',
                    //判断是否是成品
                    display: (this.drawer.copy_flag == 1) ? "inline-block" : "none",
                  },
                  on: {
                    click: () => {
                      this.copyChange(data)
                    }
                  }
                }),
                h('Button', {
                  props: Object.assign({}, this.buttonProps, {
                    icon: 'ios-add',
                    type: 'primary'
                  }),
                  style: {
                    width: '46px'
                  },
                  on: {
                    click: () => {
                      this.append(data)
                    }
                  }
                })
              ])
            ]);
          },
          view_render: (h, {root, node, data}) => {
            return h('span', {
              style: {
                display: 'inline-block',
                width: '100%'
              }
            }, [
              h('span', [
                h('Icon', {
                  props: {
                    type: 'ios-folder-outline'
                  },
                  style: {
                    marginRight: '8px',
                  }
                }),
                h('span', data.title)
              ]),
              h('span', {
                style: {
                  display: 'inline-block',
                  float: 'right',
                  marginRight: '32px'
                }
              }, [
                h('span', {
                  style: {
                    fontSize: '12px',
                  },
                }, '成本: '),
                // h('span', {
                //   style: {
                //     fontSize: '12px',
                //   },
                // }, data.total_cost + ' + ' + data.cost_price + ' * 1 = '),
                h('span', {
                  style: {
                    marginLeft: '10px',
                    color: '#2775ff',
                    fontWeight: 'bold',
                    fontSize: '12px',
                  },
                }, (parseFloat(data.total_cost)) + ' 元')
              ]),
            ]);
          },
          children: []
        },
        data: [],
        obj: {},
        current_id: '',
        total_cost: 0,
        status: '',
        copy_flag: 0,
        copy_add_flag: false,
      },
      buttonProps: {
        type: 'default',
        size: 'small',
      },
      list: {
        data: [],
        total: 0,
        size: 0,
      },
      post_flag: 0,
      allGoodsType: [
        {
          id: 2,
          name: '成品'
        },
        {
          id: 3,
          name: '半成品'
        }
      ],
      allStatus: [
        {
          id: 0,
          name: '待生效'
        },
        {
          id: 1,
          name: '已生效'
        }
      ],
      goodsCategoryOptions: [],
      editDeleteId: [],
      editAddId: [],
    };
  },
  methods: {
    goodsCategoryList() {
      getGoodsCategoryList({size: 400}).then(res => {
        this.goodsCategoryOptions = res.data.results.list.map(item => {
          return {
            value: item.id,
            label: item.name
          };
        });
      });
    },
    createByCopy($id) {
      this.getBomDetail($id, 2)//2表示复制
    },
    createGoodsCategoryByCopy(id) {
      this.$Modal.confirm({
        title: "BOM模板复制",
        content: '确定根据此BOM信息来创建该货品品类的其他货品BOM信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          let param = {bom_id: id}
          createGoodsCategoryByCopy(param).then(res => {
              if (res.data.err_no == 0) {
                this.$Message.success(res.data.results);
                this.getBomList();
              } else {
                this.$Message.error(res.data.err_msg);
              }
          })
        },
        onCancel: () => {
        }
      });
    },
    getTypeColor(type) {
      let rs = '';
      switch (type) {
        case 2://成品
          rs = 'background-color:#19be6b';
          break;
        case 3:
          rs = 'background-color:#2775ff';
          break;
        default:
          rs = '';
          break;
      }
      return rs;
    },
    deleteTreeData(id = 0) {
      this.$Modal.confirm({
        title: "删除BOM信息",
        content: '确定删除BOM信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.deleteBomInfo(id);
        },
        onCancel: () => {
        }
      });
    },
    deleteBomInfo(id = 0) {
      //删除bom
      let param = {
          id: id ? id : this.drawer.current_id,
      };
      deleteBom(param).then((res) => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              this.drawer.view_model = false;
              this.getBomList();
            }
          });
    },
    editTreeData() {
      this.editDeleteId = [];
      this.editAddId = [];
      //修改bom
      this.getBomDetail(this.drawer.current_id, 1);
      this.drawer.view_model = false;
    },
    validTree(id = 0) {
      this.$Modal.confirm({
        title: "生效BOM信息",
        content: '确定生效BOM信息？生效后将无法对BOM结构进行改动',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          //使bom表生效
          let param = {
            id: id ? id : this.drawer.current_id,
          };
          this.$axios.post('/api/create/validBom', param)
              .then((response) => {
                if (response.data.err_no === 0) {
                  this.$Message.success(response.data.results);
                  this.drawer.view_model = false;
                  this.getBomList();
                } else {
                  this.$Message.error(response.data.err_msg);
                }
              });
        },
        onCancel: () => {
        }
      });
    },
    invalidTree() {
      this.$Modal.confirm({
        title: "失效BOM信息",
        content: '确定失效BOM信息？失效后可以对BOM结构进行改动删除等操作，可能会影响生产计划',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          //使bom表生效
          let param = {
            id: this.drawer.current_id,
          };
          this.$axios.post('/api/create/invalidBom', param)
              .then((response) => {
                if (response.data.err_no === 0) {
                  this.$Message.success(response.data.results);
                  this.drawer.view_model = false;
                  this.getBomList();
                } else {
                  this.$Message.error(response.data.err_msg);
                }
              });
        },
        onCancel: () => {
        }
      });
    },
    //获取bom结构详情
    getBomDetail($id, edit_flag = 0) {
      let param = {
        params: {
          id: $id,
        }
      };
      this.drawer.data = [];
      this.drawer.current_id = $id;
      this.$axios.get('/api/create/getBomDetail', param)
          .then((response) => {
            if (response.data.err_no == 0) {
              let data = response.data.results;

              this.drawer.copy_flag = 0;
              if (edit_flag == 1) {//1表示修改 0表示查看 2表示复制
                this.editBom(data);
              } else if (edit_flag == 2) {//1表示修改 0表示查看 2表示复制
                this.drawer.copy_flag = 1;
                this.editBom(data);
              } else {
                this.viewBom(data);
              }
            }
          });
    },
    editBom(data) {
      data.render = this.drawer.parent.render;
      data.title = data.part_name + ' (' + data.part_no + ')';
      data.expand = true;
      data.children = this.formatEditBomDetail(data.children);
      this.drawer.data.push(data);
      this.drawer.model = true;
    },
    viewBom(data) {
      this.drawer.total_cost = 0;

      data.render = this.drawer.parent.view_render;
      data.title = data.part_name + ' (' + data.part_no + ')';
      data.expand = true;
      data.children = this.formatBomDetail(data.children);

      data.total_cost = this.drawer.total_cost;
      this.drawer.status = data.status;
      this.drawer.data.push(data);
      this.drawer.view_model = true;
    },
    formatBomDetail(val) {
      for (let i = 0; i < val.length; i++) {
        val[i].title = (i + 1) + '、' + val[i].part_name + ' (' + val[i].part_no + ')';
        //计算总价
        this.drawer.total_cost = NP.plus(this.drawer.total_cost, NP.times(val[i].cost_price, val[i].number));
        val[i].is_deleted !== undefined ? val[i].is_deleted : 0;
        // val[i].expand = true;
        val[i].children = this.formatBomDetail(val[i].children)
      }
      return val;
    },
    formatEditBomDetail(val) {
      for (let i = 0; i < val.length; i++) {
        val[i].title = (i + 1) + '、' + val[i].part_name + ' (' + val[i].part_no + ')';
        val[i].is_deleted != undefined ? val[i].is_deleted : 0;
        val[i].children = [];
        //修改时，只需要第一层级的数据
        // val[i].expand = true;
        // val[i].children = this.formatEditBomDetail(val[i].children)
      }
      return val;
    },
    searchSubmit() {
      this.getBomList();
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.getBomList();
    },
    //保存bom结构
    saveTreeData() {
      let post_data = [];
      this.post_flag = 0;
      for (let i = 0; i < this.drawer.data.length; i++) {
        let data = {};
        data.id = this.drawer.data[i].id;
        data.children = this.getPostData(this.drawer.data[i].children);
        post_data.push(data);
      }

      if (this.post_flag) {
        return;
      }

      let param = {
        bom: post_data,
      };
      this.$axios.post('/api/create/saveBom', param).then((res) => {
            if (res.data.err_no === 0) {
              this.$Message.success(res.data.results);
              this.drawer.model = false;
              this.getBomList();
            } else {
              this.$Message.error(res.data.err_msg);
            }
          });

    },
    saveTreeDataCategoryBatch() {
      this.$Modal.confirm({
        title: "批量保存",
        content: '确定根据此BOM信息来批量变动该货品品类的其他货品BOM信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          const editDeleteId = this.editDeleteId.filter(item => !this.editAddId.includes(item));
          const editAddId = this.editAddId.filter(item => !this.editDeleteId.includes(item));

          let post_data = [];
          this.post_flag = 0;
          for (let i = 0; i < this.drawer.data.length; i++) {
            let data = {};
            data.id = this.drawer.data[i].id;
            data.children = this.getPostData(this.drawer.data[i].children);
            post_data.push(data);
          }

          if (this.post_flag) {
            return;
          }

          let param = {
            bom: post_data,
            edit_delete_id: editDeleteId,
            edit_add_id: editAddId
          };

          saveBomGoodsCategoryBatch(param).then(res => {
            if (res.data.err_no === 0) {
              this.$Message.success(res.data.results);
              this.drawer.model = false;
              this.getBomList();
            } else {
              this.$Message.error(res.data.err_msg);
            }
          })
        },
        onCancel: () => {
        }
      });

    },
    changePage(page) {
      this.getBomList(page);
    },
    getBomList($page = 1) {
      //获取货品列表
      let param = {
        page: $page,
        part_no: this.filter.data.part_no,
        part_name: this.filter.data.part_name,
        type: this.filter.data.type,
        status: this.filter.data.status,
        goods_category_id: this.filter.data.goods_category_id,
      };
      getBomList(param).then((res) => {
        if (res.data.err_no == 0) {
          this.list.data = res.data.results.list;
          this.list.total = res.data.results.total;
          this.list.size = res.data.results.size;
        }
      });
    },
    getPostData(children) {
      if (children == undefined) {
        return [];
      }
      if (Array.prototype.isPrototypeOf(children) && children.length === 0) {
        return [];
      }

      let rs = [];
      for (let i = 0; i < children.length; i++) {
        let data = {};
        data.id = children[i].id;
        data.number = children[i].number;
        if (data.number <= 0) {
          this.$Message.error('货品数量不能小于等于0');
          this.post_flag = 1;
        }
        data.children = this.getPostData(children[i].children);
        rs.push(data)
      }
      return rs;
    },

    //获取选中的货品
    getSelectedGoods(val) {
      if (this.drawer.copy_flag && !this.drawer.copy_add_flag) {
        this.drawer.data = [];
      }

      if (!this.drawer.data.length) {
        if (val.length >= 2) {
          this.drawer.model = false;
          this.$Message.error('目前不支持批量添加货品BOM结构');
          return;
        }

        for (let i = 0; i < val.length; i++) {
          let flag = 0;
          //判断是否已经存在
          for (let j = 0; j < this.drawer.data.length; j++) {
            if (this.drawer.data[j].id == val[i].id) {
              flag = 1;
              break;
            }
          }

          if (flag) {
            continue;
          }

          let parent = {};
          parent.goods_type = val[i].type;
          parent.id = val[i].id;
          parent.title = val[i].part_name + ' (' + val[i].part_no + ')';
          parent.expand = true;
          parent.is_deleted = val[i].is_deleted != undefined ? val[i].is_deleted : 0;
          parent.render = this.drawer.parent.render;
          parent.children = [];
          this.drawer.data.push(parent);
        }
      } else {
        let main_id = this.drawer.obj.id;
        const children = this.drawer.obj.children || [];
        for (let i = 0; i < val.length; i++) {
          if (main_id == val[i].id) {
            continue;
          }

          let flag = 0;
          //判断是否已经存在
          for (let j = 0; j < this.drawer.obj.children.length; j++) {
            if (this.drawer.obj.children[j].id == val[i].id) {
              flag = 1;
              break;
            }
          }

          if (flag) {
            continue;
          }

          children.push({
            goods_type: val[i].type,
            title: val[i].part_name + ' (' + val[i].part_no + ')',
            id: val[i].id,
            number: 1,
            is_deleted: val[i].is_deleted != undefined ? val[i].is_deleted : 0,
            expand: true
          });
          this.editAddId.push(val[i].id);//用于批量处理同品类货品
        }
        this.$set(this.drawer.obj, 'children', children);
      }

      if (this.drawer.copy_flag) {
        this.copyChangeParentData();
      }
      this.drawer.model = true;
    },

    closeDrawer() {
      //关闭抽屉
      this.drawer.model = false;
      this.drawer.view_model = false;
    },
    //创建bom
    createBom(goods_type) {
      this.$refs.select_goods.select_goods.goods_type = goods_type;
      this.$refs.select_goods.showGoodsList();
      if (this.drawer.model == false) {
        //初始化
        this.drawer.data = [];
      }
    },
    ViewRenderContent(h, {root, node, data}) {
      return h('span', {
        style: {
          display: 'inline-block',
          width: '100%'
        }
      }, [
        h('span', [
          h('Icon', {
            props: {
              type: 'ios-paper-outline'
            },
            style: {
              marginRight: '8px',
              //判断是否是成品
              display: ([2, 3].indexOf(data.goods_type) > -1) ? "none" : "inline-block",
            }
          }),
          h('Icon', {
            props: {
              type: 'ios-folder-outline'
            },
            style: {
              marginRight: '8px',
              //判断是否是成品
              display: ([2, 3].indexOf(data.goods_type) > -1) ? "inline-block" : "none",
            }
          }),
          h('span', data.title)
        ]),
        h('span', [
          h('span', {
            style: {
              marginLeft: '10px',
              color: '#2775ff',
              fontWeight: 'bold',
            },
          }, data.number + ' 个')
        ]),

        h('span', {
          style: {
            display: 'inline-block',
            float: 'right',
            marginRight: '32px'
          }
        }, [
          h('span', {
            style: {
              fontSize: '12px',
            },
          }, data.cost_price!==undefined ? (data.cost_price.toString() + ' * ' + data.number.toString() + ' = '):''),
          h('span', {
            style: {
              marginLeft: '10px',
              color: '#2775ff',
              fontWeight: 'bold',
              fontSize: '12px',
            },
          }, data.cost_price!==undefined ? (NP.times(data.cost_price, data.number)) + ' 元': '')
        ])
      ]);
    },
    renderContent(h, {root, node, data}) {
      return h('span', {
        style: {
          display: 'inline-block',
          width: '100%'
        }
      }, [
        h('span', [
          h('Icon', {
            props: {
              type: 'ios-paper-outline'
            },
            style: {
              marginRight: '8px',
              //判断是否是成品
              display: ([2, 3].indexOf(data.goods_type) > -1) ? "none" : "inline-block",
            }
          }),
          h('Icon', {
            props: {
              type: 'ios-folder-outline'
            },
            style: {
              marginRight: '8px',
              //判断是否是成品
              display: ([2, 3].indexOf(data.goods_type) > -1) ? "inline-block" : "none",
            }
          }),
          h('span', data.title)
        ]),
        h('Input', {
          props: {
            value: data.number ,
            type: 'number',
          },
          domProps: {
            id: 'drawer-' + node.nodeKey
          },
          style: {
            width: '50px',
            marginLeft: '10px',
          },
          on: {
            'on-change': (event) => {
              data.number = event.target.value;
              this.$nextTick(() => {
                let Odiv = document.getElementById('drawer-' + node.nodeKey);
                let iptarr = Odiv.getElementsByTagName('input');
                iptarr[0].focus();
              })
            }
          },
        }),
        h('span', {
          style: {
            display: 'inline-block',
            float: 'right',
            marginRight: '32px'
          }
        }, [
          // h('Button', {
          //     props: Object.assign({}, this.buttonProps, {
          //         icon: 'ios-add'
          //     }),
          //     style: {
          //         marginRight: '8px'
          //     },
          //     on: {
          //         click: () => {
          //             this.append(data)
          //         }
          //     }
          // }),
          h('Button', {
            props: Object.assign({}, this.buttonProps, {
              icon: 'ios-remove'
            }),
            on: {
              click: () => {
                this.remove(root, node, data)
              }
            }
          })
        ])
      ]);
    },
    append($data) {
      this.drawer.obj = $data;
      this.drawer.copy_add_flag = true;
      this.createBom(0);
    },
    copyChange($data) {
      this.drawer.obj = $data;
      this.drawer.copy_add_flag = false;
      this.createBom('2,3');
    },
    copyChangeParentData() {
      this.drawer.data[0].children = this.drawer.obj.children;
    },
    remove(root, node, data) {
      const parentKey = root.find(el => el === node).parent;
      const parent = root.find(el => el.nodeKey === parentKey).node;
      const index = parent.children.indexOf(data);
      this.editDeleteId.push({id:data.id, number:data.number});
      parent.children.splice(index, 1);
    }
  },
  mounted() {
    this.getBomList();

    this.goodsCategoryList();
  },
  created() {
    this.aCheck.plateName = 'create_manage';
  },
  components: {
    SelectGoods,
  }
};
</script>

<style scoped>
#main {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

#panel-header {
  position: relative;
  border: none;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

.client_create {
  position: absolute;
  right: 0;
  top: -8px;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

.main-body {
  /*margin-top: 12px;*/
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
}

.item-body {
  width: 24%;
  margin: 10px 0.5% 5px 0.5%;
}

.main-body .bom-body {
  text-align: center;
  overflow: hidden;
}

.main-body .bom-img-div {
  width: 100%;
  height: 230px;
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
  font-size: 18px;
  background-color: #2775ff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.bg-fff {
  background-color: #fff !important;
}

.main-body .bom-img-div:hover .copy, .main-body .bom-img-div:hover .list-operate {
  display: block;
}

.main-body .bom-img-div:hover .goodsCategoryCopy {
  display: block;
}

.main-body .bom-img {
  height:230px;
}

.copy {
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  line-height: 20px;
  padding: 4px 6px;
  font-size: 12px;
  z-index: 999;
  display: none;
}

.list-delete {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  line-height: 20px;
  padding: 4px 6px;
  font-size: 12px;
  z-index: 999;
  display: none;
}

.list-valid {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  line-height: 20px;
  padding: 4px 6px;
  font-size: 12px;
  z-index: 999;
  display: none;
}
.goodsCategoryCopy {
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  line-height: 20px;
  padding: 4px 6px;
  font-size: 12px;
  z-index: 999;
  display: none;
}
</style>
<style>
#main .ivu-card {
  background-color: #fafafa;
}

#main .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#main .ivu-form-item {
  margin-bottom: 0;
}

#filter-bar .ivu-form-item {
  margin-right: 8px;
}

.tree-render .ivu-tree-title {
  width: 100%;
}

.drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}

#main .page {
  text-align: right;
}

#main .ivu-card-body {
  padding: 16px 16px 10px 16px;
}
</style>
