<template id="menu">
  <Menu theme="light" :active-name="active_name" @on-select="onselect" width="auto">
    <MenuGroup title="生产管理" v-if="aCheck.moduleCheck('create_module')">
      <MenuItem name="5" v-if="aCheck.pageCheck('create_operate')">
        <Icon type="ios-archive" />
        生产领料货品
      </MenuItem>
      <MenuItem name="2" v-if="aCheck.pageCheck('create_plan')">
        <Icon type="ios-pie" />
        生产计划
      </MenuItem>
      <MenuItem name="4" v-if="aCheck.pageCheck('create_operate')">
        <Icon type="ios-hammer"/>
        生产操作
      </MenuItem>
      <!--&lt;!&ndash;          <MenuItem name="3">&ndash;&gt;-->
      <!--&lt;!&ndash;              <Icon type="md-albums" />&ndash;&gt;-->
      <!--&lt;!&ndash;              生产需求&ndash;&gt;-->
      <!--&lt;!&ndash;          </MenuItem>&ndash;&gt;-->

    </MenuGroup>
    <MenuGroup title="结构管理" v-if="aCheck.moduleCheck('struct_module')">
      <MenuItem name="1" v-if="aCheck.pageCheck('bom_list')">
        <Icon type="ios-map"/>
        BOM表
      </MenuItem>
    </MenuGroup>
  </Menu>
</template>

<script>
import * as loginUtil from '@/utils/login'

export default {
  name: "CreateManageMenu",
  data() {
    return {
      active_name: this.active,
      menu_name: this.menuName,
    };
  },
  methods: {
    onselect(name) {
      this.$emit('func', name);
      localStorage.setItem(this.menu_name, name);

      loginUtil.setExpireTime();
    },
    setNavInfo(name) {
      this.active_name = name.toString()
    }
  },
  created() {
    this.aCheck.plateName = 'create_manage';
  },

  props: ["active", "menuName"],
};
</script>

<style scoped>
#menu {
  width: 200px;
  position: absolute;
  left: 0;
}
</style>
